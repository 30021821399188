import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link } from "@reach/router";
import axios from "axios";

export default class LogPage extends Component {
  state = {
    loading: true,
    contacts: [],
  };

  loadData = () => {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/contact`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          contacts: resp.data.contacts,
        });
      })
      .catch(net.handleError2);
  };

  componentDidMount() {
    this.loadData();
  }

  constructor(props) {
    super();
  }

  addSub = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      return;
    }

    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/contact`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        email: this.state.email,
        type: "cc_2021_09_x",
      },
    })
      .then((resp) => {
        this.setState({
          contacts: resp.data.contacts,
          email: "",
        });
        halps.alertVanish("Added");
      })
      .catch(net.handleError2);
  };

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">User Contact Us Submissions</h2>

        <div className="mt-3 w-full md:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4">
            <h3>Add Submission for Sept, 2021</h3>

            <div className="flex mt-3">
              <form action="" onSubmit={this.addSub}>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={this.formInputUpdate}
                  value={this.state.email}
                  className="bg-f1 rounded-md flex-grow focus:ring-4 focus:ring-lav"
                  placeholder="Email *"
                  required
                />
                <button type="submit" className="ml-2 px-4 py-2 inline-block text-white bg-lav">
                  Add Submission
                </button>
              </form>
            </div>
          </div>
        </div>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Created</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Email</th>
              <th className="border p-4">First Name</th>
              <th className="border p-4">Last Name</th>
              <th className="border p-4">Comments</th>
            </tr>
          </thead>
          <tbody>
            {this.state.contacts.map((c) => {
              return (
                <tr key={c.id} className="hover:bg-gray-300">
                  <td className="border p-4">{halps.dshort(c.created)}</td>
                  <td className="border p-4">{c.type}</td>

                  <td className="border p-4 underline cursor-pointer">
                    <Link to={`/users/${c.email}`}>{c.email}</Link>
                  </td>
                  <td className="border p-4">{c.first_name}</td>
                  <td className="border p-4">{c.last_name}</td>
                  <td className="border p-4">{c.comments}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
