import React, { Component, useState } from "react";
import { Link, navigate } from "@reach/router";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as halps from "./common/Halps";
import * as net from "./common/Net";
import * as icons from "./common/Icons";

export default class ClassesPage extends Component {
  state = {
    loading: true,
    classes: [],
    classSlots: [],
    showForm: false,
    slot: "none",
    year: "",
    month: "",
    day: "",
    zoom: "",
    date: null,
  };

  componentDidMount() {
    net.getClasses(this.props.user.token).then((data) => {
      this.setState({ loading: false, classes: data.classes, classSlots: data.classSlots });
    });
  }

  constructor(props) {
    super();
  }

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event, setSpin) => {
    setSpin(true);
    event.preventDefault();
    if (!this.state.date) {
      setSpin(false);
      halps.alertError("", "Please choose a date");
      return;
    }

    if (this.state.slot === "none") {
      setSpin(false);
      halps.alertError("", "Please choose a class slot");
      return;
    }

    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/classes`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        class_slot_id: this.state.slot,
        // month: parseInt(this.state.month, 10),
        // year: parseInt(this.state.year, 10),
        // day: parseInt(this.state.day, 10),
        // zoom_link: this.state.zoom,
        date: halps.dateToDay(this.state.date),
      },
    })
      .then((resp) => {
        setSpin(false);
        // On success navigate to the new page
        navigate(`/classes/${resp.data.newClass.id}`);
      })
      .catch((error) => {
        setSpin(false);
        net.handleError2(error);
      });
  };

  // Standard method of show/hide form for class and on demand pages
  showForm = () => {
    this.setState((s, p) => ({ showForm: !s.showForm }));
  };

  setDate = (date) => {
    this.setState({ date });
    console.log(date);
    console.log(halps.dateToDay(date));
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Live Classes</h2>
        <div className="mt-2">
          <Link to="/past-classes" className="text-sm underline cursor-pointer">
            View past classes
          </Link>
        </div>
        <h3 className="mt-3">
          Add New Class{" "}
          <icons.plus cn={"h-8 w-8 inline-block cursor-pointer"} clack={this.showForm} />{" "}
        </h3>
        {this.state.showForm ? (
          <AddClassForm
            classSlots={this.state.classSlots}
            year={this.state.year}
            month={this.state.month}
            day={this.state.day}
            zoom={this.state.zoom}
            slot={this.state.slot}
            formInputUpdate={this.formInputUpdate}
            formSubmit={this.handleSubmit}
            setDate={this.setDate}
            date={this.state.date}
          />
        ) : (
          ""
        )}
        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Start Time</th>
              <th className="border p-4">Status</th>
              <th className="border p-4">Teacher</th>
              <th className="border p-4">Booked</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Duration</th>
            </tr>
          </thead>
          <tbody>
            {this.state.classes.map((c) => {
              return (
                <tr key={c.id} className="hover:bg-gray-300">
                  <td className="border p-4  underline cursor-pointer">
                    <Link to={`/classes/${c.id}`}>{halps.dshort(c.start_time)}</Link>
                  </td>

                  <td className={`border p-4 ${c.status === "canceled" ? "text-red-600" : ""}`}>
                    {c.status}
                  </td>
                  <td className="border p-4 uppercase">{c.teacher_id}</td>
                  <td className="border p-4">{c.bookings_count}</td>
                  <td className="border p-4">{c.type}</td>
                  <td className="border p-4">{c.duration}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function AddClassForm(props) {
  let [formDisabled, setFormDisabled] = useState(false);

  const setSpinAndDisabled = (val) => {
    setFormDisabled(val);
  };

  const submitForm = async (event) => {
    await props.formSubmit(event, setSpinAndDisabled);
  };

  return (
    <div className="w-full md:w-4/12 bg-beau shadow-md p-5 mt-3">
      <div className="py-8 px-8 rounded-xl">
        <h2 className="text-lg">Add A New Class</h2>
        <form action="" className="mt-4" onSubmit={submitForm}>
          <select
            className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
            id="slot"
            name="slot"
            onChange={props.formInputUpdate}
            value={props.slot}
          >
            <option value="none">Select Class Slot</option>
            {props.classSlots.map((cs) => (
              <option value={cs.id}>{cs.id}</option>
            ))}
          </select>

          <div className="text-sm mt-2">Date (PST)</div>
          <DatePicker
            className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
            selected={props.date}
            onChange={(date) => props.setDate(date)}
            dateFormat="yyyy/MM/dd"
            placeholderText="YYYY/MM/DD"
          />

          {/* <input
            type="number"
            id="year"
            name="year"
            className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
            placeholder="Year eg 2021"
            value={props.year}
            onChange={props.formInputUpdate}
            min="2019"
            max="2030"
            required
          />

          <div className="text-sm mt-2">Month</div>
          <input
            type="number"
            id="month"
            name="month"
            className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
            placeholder="Month eg 11*"
            value={props.month}
            onChange={props.formInputUpdate}
            required
            min="1"
            max="12"
          />

          <div className="text-sm mt-2">Day</div>
          <input
            type="number"
            id="day"
            name="day"
            className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
            placeholder="Day (1-31) eg 26*"
            value={props.day}
            onChange={props.formInputUpdate}
            required
          /> */}

          {/* <div className="text-sm mt-2">Zoom Link</div>
          <input
            type="url"
            id="zoom"
            name="zoom"
            className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
            placeholder="Zoom Link *"
            value={props.zoom}
            onChange={props.formInputUpdate}
            required
          /> */}

          <button
            disabled={formDisabled ? "true" : ""}
            type="submit"
            className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
          >
            {formDisabled ? <icons.Spinline /> : "Add New Class"}
          </button>
        </form>
      </div>
    </div>
  );
}
