import React, { Component } from "react";
export default class HomePage extends Component {
  //   state = {
  //     loading: true,
  //     needsLogin: false,
  //     user: {},
  //     fam: {},
  //   };

  componentDidMount() {}

  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="bg-beau mx-auto max-w-2xl p-9 mt-4 text-gray text-gray-800">
        <h2 className="text-center text-xl  mb-4 mx-auto  p-3 font-bold ">Admin Home</h2>
        <div className="max-w-xl mx-auto ">
          <p>
            Welcome to the new horribly awesome Chorus Admin, guaranteed to make your eyes bleed!
          </p>
          <p className="mt-3">
            Please see{" "}
            <a
              href="https://docs.google.com/document/d/1bn-_bfdhxXhftLDDouE9P-9YBUWNpDY7oSag7Zcugsk/edit#heading=h.x3ecpsceqdjt"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              this google doc for basic info about this admin.
            </a>
          </p>
          <h2 className="text-lg font-semibold mt-6">Glossary of user fields:</h2>
          <p className="-mt-3">
            <br />
            <ul className="list-inside list-disc">
              <li>
                <strong>Trial Ends</strong>: This is the date the trial ends. If you subtract two
                weeks, you'll know when they created their account (in most cases)
              </li>
              <li>
                <strong># joined</strong>: How many live classes they actually joined (vs just
                booked){" "}
              </li>
              <li>
                <strong>Bk</strong>: How many live classes they have booked
              </li>
              <li>
                <strong>ODV</strong>: How many on demand classes have they started (viewed)
              </li>
              <li>
                <strong>ODV50</strong>: How many on demand classes have they watched 50% or more of
              </li>
            </ul>
          </p>
        </div>
      </div>
    );
  }
}
