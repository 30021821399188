import React, { Component } from "react";
// import { Link } from "@reach/router";
import axios from "axios";
import { Link, navigate } from "@reach/router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as halps from "./common/Halps";
import * as net from "./common/Net";

export default class OnDemandPage extends Component {
  state = {
    loading: true,
    od: null,
    teachers: [],
    viewCount: 0,
    recDate: null,
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/on-demands/${this.props.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        console.log(resp);
        this.setOD(resp.data);
      })
      .catch(net.handleError2);
  }

  setOD(data) {
    this.setState({
      loading: false,
      od: data.ondemand,
      teachers: data.teachers,

      recDate: halps.dateFromGo(data.ondemand.recorded_time),

      viewCount: data.view_count,

      class_id: data.ondemand.class_id,
      name: data.ondemand.name,
      duration: data.ondemand.duration,
      description: data.ondemand.description,
      recorded_time: data.ondemand.recorded_time,
      image_preview_link: data.ondemand.image_preview_link,
      intro_len: data.ondemand.intro_len,
      teacher_id: data.ondemand.teacher_id,

      status: data.ondemand.status,
      type: data.ondemand.type,
      event_type: data.ondemand.event_type,
      video_link: data.ondemand.video_link,
      share_asset_link: data.ondemand.share_asset_link,
    });
  }

  updateOD = () => {
    const rc = halps.dateToDatetime(this.state.recDate);
    console.log(rc);
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/on-demands/${this.props.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        class_id: this.state.class_id,
        name: this.state.name,
        duration: parseInt(this.state.duration, 10),
        description: this.state.description,
        recorded_date: rc,
        intro_len: parseInt(this.state.intro_len, 10),
        teacher_id: this.state.teacher_id,
        image_preview_link: this.state.image_preview_link,

        status: this.state.status,
        type: this.state.type,
        event_type: this.state.event_type,
        video_link: this.state.video_link,
        share_asset_link: this.state.share_asset_link,
      },
    })
      .then((resp) => {
        this.setOD(resp.data);
        halps.alertVanish("On-Demand info updated.");
      })
      .catch(net.handleError2);
  };

  addToCommFeed = () => {
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/on-demands/${this.props.id}/comm-feed`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setOD(resp.data);
        halps.alertVanish("Posted!.");
      })
      .catch(net.handleError2);
  };

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  constructor(props) {
    super();
  }

  formSubmit = (e) => {
    e.preventDefault();
    this.updateOD();
  };

  setDate = (date) => {
    this.setState({ recDate: date });
    console.log(date);
    console.log(halps.dateToDay(date));
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    if (this.state.error) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Error</h3>
          <p>{this.state.error}</p>
        </>
      );
    }
    const od = this.state.od;
    return (
      <div className="container mx-auto mt-4">
        <h2 className="text-2xl">
          On-Demand {od.name} with {od.teacher_id}
        </h2>

        <h3 className="text-lg mt-1">
          Recorded {halps.dshortd(od.recorded_time)} - {od.id}
        </h3>

        {od.status === "draft" ? (
          <h3 className="text-xl mt-3 text-red-700">
            *** This on demand is currently in a draft state. Please edit below to make active
          </h3>
        ) : (
          ""
        )}

        <div className="w-full md:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Summary</h3>
            <ul className="list-disc ml-8 mt-3">
              <li className="">
                Status: <strong>{od.status}</strong>
              </li>
              <li className="">
                Event Type: <strong>{od.event_type}</strong>
              </li>
              <li className="">
                Type: <strong>{od.type}</strong>
              </li>
              <li className="">
                Views: <strong>{this.state.viewCount}</strong>
              </li>
              <li className="">Recorded: {halps.dshort(od.recorded_time)}</li>
              <li className="">
                <a
                  rel="noreferrer"
                  href={`${halps.webappBase()}/on-demand/${od.id}`}
                  target="_blank"
                  className="underline cursor-pointer"
                >
                  View in webapp
                </a>
                <a
                  rel="noreferrer"
                  href={`${halps.webappBase()}/odb/${od.id}`}
                  target="_blank"
                  className="underline cursor-pointer pl-8"
                >
                  View in webapp onboarding
                </a>
              </li>
              <li className="">
                {od.in_community_feed ? (
                  <div>Already in community feed</div>
                ) : (
                  <div onClick={() => this.addToCommFeed()} className="underline cursor-pointer">
                    Add to community feed
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full md:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Edit On-Demand</h3>
            <div className="mt-2 text-sm underline cursor-pointer">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/document/d/1bn-_bfdhxXhftLDDouE9P-9YBUWNpDY7oSag7Zcugsk/edit#heading=h.bwjbgs8yznjz"
              >
                Instructions for working with on demand videos
              </a>
            </div>
            <form className="mt-3" action="" onSubmit={this.formSubmit}>
              <div className="text-sm mt-2">Class ID</div>
              <input
                type="text"
                id="class_id"
                name="class_id"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Class ID"
                value={this.state.class_id}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Name</div>
              <input
                type="text"
                id="name"
                name="name"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Name"
                value={this.state.name}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Duration (actual duration is {od.duration})</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="duration"
                name="duration"
                onChange={this.formInputUpdate}
                value={this.state.duration}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>

              <div className="text-sm mt-2">Description</div>
              <input
                type="text"
                id="description"
                name="description"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Description"
                value={this.state.description}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Recorded Date (PST)</div>
              <DatePicker
                className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
                selected={this.state.recDate}
                onChange={(date) => this.setDate(date)}
                dateFormat="yyyy/MM/dd HH:mm"
                placeholderText="YYYY/MM/DD HH:mm"
                showTimeSelect
              />

              <div className="text-sm mt-2">Intro Length (seconds)</div>
              <input
                type="number"
                id="intro_len"
                name="intro_len"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Intro Length"
                value={this.state.intro_len}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Teacher</div>
              <select
                className="bg-f1  rounded-md w-full focus:ring-4 focus:ring-lav"
                id="teacher_id"
                name="teacher_id"
                onChange={this.formInputUpdate}
                value={this.state.teacher_id}
              >
                <option value="none">Teacher</option>
                {this.state.teachers.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.first_name}
                  </option>
                ))}
              </select>

              <div className="text-sm">Status</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="status"
                name="status"
                onChange={this.formInputUpdate}
                value={this.state.status}
              >
                <option value="draft">Draft</option>
                <option value="active">Active</option>
                <option value="canceled">Canceled</option>
              </select>

              <div className="text-sm">Type</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="type"
                name="type"
                onChange={this.formInputUpdate}
                value={this.state.type}
              >
                <option value="energize">Energize</option>
                <option value="focus">Focus</option>
                <option value="sleep">Sleep</option>
                <option value="unwind">Unwind</option>
                <option value="chorus10">Chorus 10</option>
              </select>

              <div className="text-sm">Event Type</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="event_type"
                name="event_type"
                onChange={this.formInputUpdate}
                value={this.state.event_type}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>

              <div className="text-sm mt-2">Video Link</div>
              <div className="ml-2 mt-2 text-sm underline cursor-pointer">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://console.cloud.google.com/storage/browser/chorus-public/on-demand?project=chorus-project&pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&prefix=&forceOnObjectsSortingFiltering=false"
                >
                  Please first upload your video to Google Cloud Storage the paste link here
                </a>
              </div>

              <div className="ml-2 mt-2 text-sm underline cursor-pointer">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://docs.google.com/document/d/1bn-_bfdhxXhftLDDouE9P-9YBUWNpDY7oSag7Zcugsk/edit#heading=h.bwjbgs8yznjz"
                >
                  Doc here
                </a>
              </div>

              <input
                type="url"
                id="video_link"
                name="video_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav mt-1"
                placeholder="Video Link"
                value={this.state.video_link}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Sharable Asset Link</div>
              <input
                type="url"
                id="share_asset_link"
                name="share_asset_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Sharable Asset Link"
                value={this.state.share_asset_link}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Image Preview Link</div>
              <p className="text-xs italic ml-3">Add this for audio-only</p>
              <input
                type="url"
                id="image_preview_link"
                name="image_preview_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Image Preview Link"
                value={this.state.image_preview_link}
                onChange={this.formInputUpdate}
              />

              <input
                className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
                type="submit"
                value="Save Changes"
              />
            </form>
          </div>
        </div>

        <h2 className="mt-4 text-2xl font-medium">All Fields</h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          <tbody>
            {Object.keys(od)
              .sort()
              .map((k) => (
                <tr key={k}>
                  <th className="border border-gray-400 p-3">{k}</th>
                  <td className="border border-gray-400 p-3">{`${od[k]}`}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
