import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link, navigate } from "@reach/router";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class PastEventsPage extends Component {
  state = {
    loading: true,
    events: [],
    eventSlots: [],
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/events/past`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          events: resp.data.events,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  cloneEventAction = (eventID, title) => {
    return (event) => {
      confirmAlert({
        message: `Do you really want to clone article "${title}?"`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.cloneEvent(eventID)
          },
          {
            label: 'No'
          }
        ]
      });
    };
  };

  cloneEvent = async (eventID) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events/${eventID}/clone`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
    })
      .then((resp) => {
        // On success navigate to the new page
        navigate(`/events/${resp.data.event.id}`);
      })
      .catch((error) => {
        net.handleError2(error);
      });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Past Events</h2>

        <div className="mt-2">
          <Link to="/events" className="text-sm underline cursor-pointer">
            View current events
          </Link>
        </div>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Title</th>
              <th className="border p-4">Start Time</th>
              <th className="border p-4">Teachers</th>
              <th className="border p-4">Booked</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Status</th>
              <th className="border p-4"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.events.map((c) => {
              return (
                <tr key={c.id} className="hover:bg-gray-300">
                  <td className="border p-4 underline cursor-pointer">
                    <Link to={`/events/${c.id}`}>{c.title_a}</Link>
                  </td>
                  <td className="border p-4">
                    {halps.dshort(c.start_time)}
                  </td>
                  <td className="border p-4 uppercase">{c.teacher_ids.map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(', ')}</td>
                  <td className="border p-4">{c.bookings_count}</td>
                  <td className="border p-4">{c.type}</td>
                  <td className="border p-4">{c.status}</td>
                  <td className="border p-4">
                    <button 
                      className="block text-white float-right bg-lav ml-1 mt-3 p-3 duration-300 rounded-sm hover:bg-russ  focus:ring-4 focus:ring-lav"
                      onClick={this.cloneEventAction(c.id, c.title_a)}
                    >
                      Clone
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
