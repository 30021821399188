import React, { Component } from "react";
// import { Link } from "@reach/router";
import axios from "axios";
import { Link } from "@reach/router";
import DatePicker from "react-datepicker";

import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { isProd } from "./common/Consts";
import Select from "react-select";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Spinline } from "./common/Icons";

export default class EventPage extends Component {
  state = {
    loading: true,
    zoomLoadMsg: "Loading zoom info...",
    error: null,
    event: {},
    bookings: [],
    bookingUsers: null,
    reminders: [],
    all_teachers: [],
    teachers: [],
    contentState: EditorState.createEmpty(),
    fieldErrors: {},
    submitDisabled: false,
    zoomInfoLoading: false,
  };

  componentDidMount() {
    net.getEvent(this.props.user.token, this.props.id).then((data) => {
      if (!data) {
        return;
      }
      if (data.error) {
        this.setState({ loading: false, error: data.error });
      } else {
        this.setEventState(data);
        this.fetchEventZoomMeeting();
      }
    });
  }

  setEventState = (data) => {
    let allTeachers = data.teachers.map((t) => {
      return { value: t.id, label: t.first_name };
    });
    let teachers = allTeachers.filter((t) => {
      return data.event.teacher_ids.includes(t.value);
    });

    let editorState = this.state.contentState;
    const contentBlock = htmlToDraft(data.event.content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      // editorState = EditorState.createWithContent(contentState);
      editorState = EditorState.push(this.state.contentState, contentState);
    }

    this.setState({
      loading: false,
      event: data.event,
      bookings: data.bookings,
      bookingUsers: data.bookingUsers,
      all_teachers: allTeachers,
      teachers: teachers,
      status: data.event.status,
      visibility: data.event.visibility,
      type: data.event.type,
      duration: data.event.duration,
      zoom_link: data.event.zoom_link,
      teacher_ids: data.event.teacher_ids,
      title_a: data.event.title_a,
      title_b: data.event.title_b,
      short_description_a: data.event.short_description_a,
      short_description_b: data.event.short_description_b,
      long_description: data.event.long_description,
      comment_thread_id: data.event.comment_thread_id,
      image_link: data.event.image_link,
      media_link: data.event.media_link,
      reminders: data.reminders,
      start_time: halps.dateFromGo(data.event.start_time),
      end_time: halps.dateFromGo(data.event.end_time),
      contentState: editorState,
      fieldErrors: {},
      klaviyo_event_name: data.event.klaviyo_event_name,
      total_capacity: data.event.total_capacity,
      price: data.event.price,
      coupon_price: data.event.coupon_price,
      suggested_donation_price: data.event.suggested_donation_price,
      addr1: data.event.addr1,
      addr2: data.event.addr2,
      city: data.event.city,
      country: data.event.country,
      phone: data.event.phone,
      state: data.event.state,
      zip: data.event.zip,
      lat: data.event.lat,
      long: data.event.long,
    });
  };

  setZoomState = (data) => {
    let zoomMeeting = data.zoom_meeting ? data.zoom_meeting : null;
    let apiModel = data.api_model ? data.api_model : null;
    this.setState({
      zoomLoadMsg: null,
      zoomMeeting: zoomMeeting,
      zoomMeetingID: zoomMeeting ? zoomMeeting.meeting_id : null,
      zoomLink: zoomMeeting ? zoomMeeting.zoon_link : null,
      zoom_description: zoomMeeting ? zoomMeeting.description : null,
      zoomAPIModel: apiModel,
    });
  };

  setSubmitDisabled = (val) => {
    this.setState({
      submitDisabled: val,
    });
  };

  setZoomInfoLoading = (val) => {
    this.setState({
      zoomInfoLoading: val,
    });
  };

  fetchEventZoomMeeting = async () => {
    if (!this.state.event.support_zoom) {
      return;
    }

    this.setZoomInfoLoading(true);
    net.getEventZoomMeeting(this.props.user.token, this.props.id).then((data) => {
      this.setZoomInfoLoading(false);
      if (!data) {
        this.setState({ zoomLoadMsg: `Fail load zoom info, see console logs` });
        return;
      }
      if (data.error) {
        this.setState({
          zoomLoadMsg: `Fetch zoom info error: ${data.error}`,
          error: `zoom error: ${data.error}`,
        });
      } else {
        this.setZoomState(data);
      }
    });
  };

  setErrorsState = (error) => {
    let fieldErrors = {};
    if (error && error.meta) {
      fieldErrors = error.meta;
    }
    this.setState({
      fieldErrors,
    });
  };

  joinToggle = (bid) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/event-bookings/${bid}/join-toggle`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setEventState(resp.data);
      })
      .catch(net.handleError2);
  };

  gu = (uid) => {
    return this.state.bookingUsers[uid] || {};
  };

  updateEvent = () => {
    this.setSubmitDisabled(true);
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        title_a: this.state.title_a,
        title_b: this.state.title_b,
        short_description_a: this.state.short_description_a,
        short_description_b: this.state.short_description_b,
        long_description: this.state.long_description,
        image_link: this.state.image_link,
        media_link: this.state.media_link,
        content: draftToHtml(convertToRaw(this.state.contentState.getCurrentContent())),
        comment_thread_id: this.state.comment_thread_id,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        teacher_ids: this.state.teacher_ids,
        visibility: this.state.visibility,

        klaviyo_event_name: this.state.klaviyo_event_name,
        total_capacity: parseInt(this.state.total_capacity, 10),
        price: parseInt(this.state.price, 10),
        coupon_price: parseInt(this.state.coupon_price, 10),
        suggested_donation_price: parseInt(this.state.suggested_donation_price, 10),
        addr1: this.state.addr1,
        addr2: this.state.addr2,
        city: this.state.city,
        country: this.state.country,
        phone: this.state.phone,
        state: this.state.state,
        zip: this.state.zip,
        lat: this.state.lat,
        long: this.state.long,
      },
    })
      .then((resp) => {
        this.setSubmitDisabled(false);
        if (this.state.zoomAPIModel) {
          let updatedStartDt = halps.dateFromGo(resp.data.event.start_time);
          let oldStartDt = halps.dateFromGo(this.state.zoomAPIModel.start_time);
          let needUpdateZoomModel =
            resp.data.event.duration != this.state.zoomAPIModel.duration ||
            `${updatedStartDt}` != `${oldStartDt}` ||
            resp.data.event.title_a != this.state.zoomAPIModel.topic;
          if (needUpdateZoomModel) {
            this.fetchEventZoomMeeting();
          }
        }
        this.setEventState(resp.data);
        halps.alertVanish("Updated.");
      })
      .catch((error) => {
        this.setSubmitDisabled(false);
        let apiErr = net.handleError2(error);
        this.setErrorsState(apiErr);
      });
  };

  updateEventZoomMeeting = () => {
    this.setSubmitDisabled(true);
    axios({
      method: this.state.zoomMeeting ? "PUT" : "POST",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}/zoom`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        description: this.state.zoom_description,
      },
    })
      .then((resp) => {
        this.setSubmitDisabled(false);
        this.setZoomState(resp.data);
        halps.alertVanish("Zoom Info Updated.");
      })
      .catch((error) => {
        this.setSubmitDisabled(false);
        net.handleError2(error);
      });
  };

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  formInputSelectTeacher = (teachers) => {
    this.setState({
      teachers,
      teacher_ids: teachers.map((t) => t.value),
    });
  };

  validImageLink = (link) => {
    return link ? link.match(/\.(jpeg|jpg|gif|png)$/) != null : false;
  };

  validMediaLink = (link) => {
    return link ? link.match(/\.(mp3|mp4)$/) != null : false;
  };

  constructor(props) {
    super();
  }

  formSubmit = (e) => {
    e.preventDefault();
    this.updateEvent();
  };

  zoomFormSubmit = (e) => {
    e.preventDefault();
    this.updateEventZoomMeeting();
  };

  onContentChange = (e) => {
    console.log("EVENT", e);
    this.setState({
      // EditorState.createWithContent(ContentState.createFromText(data.event.content))
      contentState: e,
    });
  };

  publishEvent = (e) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}/publish`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
    })
      .then((resp) => {
        this.setEventState(resp.data);
        halps.alertVanish("Published.");
      })
      .catch((error) => {
        let apiErr = net.handleError2(error);
        this.setErrorsState(apiErr);
      });
  };

  cancelEvent = (e) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}/cancel`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
    })
      .then((resp) => {
        this.setEventState(resp.data);
        halps.alertVanish("Cancelled.");
      })
      .catch(net.handleError2);
  };

  unpublishEvent = (e) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}/unpublish`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
    })
      .then((resp) => {
        this.setEventState(resp.data);
        halps.alertVanish("Unpublished.");
      })
      .catch(net.handleError2);
  };

  rollSA = (e) => {
    e.preventDefault();
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/events/${this.state.event.id}/roll-sa`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setEventState(resp.data);
        halps.alertVanish("Rolled");
      })
      .catch(net.handleError2);
  };

  bookUser = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/event-bookings`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        event_id: this.state.event.id,
        user_id: this.state.book_user,
      },
    })
      .then((resp) => {
        this.setEventState(resp.data);
        halps.alertVanish("Booked");
      })
      .catch(net.handleError2);
  };

  setStartTime = (startTime) => {
    this.setState({ start_time: startTime });
    console.log(startTime);
    console.log(halps.dateToDay(startTime));
  };

  setEndTime = (endTime) => {
    this.setState({ end_time: endTime });
    console.log(endTime);
    console.log(halps.dateToDay(endTime));
  };

  bookSummary = () => {
    let sum = { joined: 0 };
    const bks = this.state.bookings;
    for (let b of bks) {
      if (Object.prototype.hasOwnProperty.call(sum, b.status)) {
        sum[b.status] += 1;
      } else {
        sum[b.status] = 1;
      }
      if (b.joined) {
        sum["joined"]++;
      }
    }
    return sum;
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    if (this.state.error) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Error</h3>
          <p>{this.state.error}</p>
        </>
      );
    }
    const c = this.state.event;
    const gu = this.gu;

    const bks = this.bookSummary();

    return (
      <div className="container mx-auto mt-4">
        <h2 className="text-2xl mb-4">
          {c.title_a} <span className="underline">{halps.dshort(c.start_time)}</span> with{" "}
          {c.teacher_ids.map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(", ")}
        </h2>

        {c.status === "draft" ? (
          <h3 className="text-xl mt-3 text-red-700">
            *** This event is currently in a draft state. Please edit below to make active
          </h3>
        ) : (
          ""
        )}

        <div className="flex mt-4">
          <div className="w-full  bg-beau shadow-md">
            <div className="p-4 md:p-8">
              {(c.status === "draft" || c.status === "canceled") && (
                <button
                  className="block text-white float-right bg-lav ml-1 mt-3 p-3 duration-300 rounded-sm hover:bg-russ  focus:ring-4 focus:ring-lav"
                  onClick={this.publishEvent}
                >
                  Publish
                </button>
              )}
              {(c.status === "active" || c.status === "draft") && (
                <button
                  className="block text-white float-right bg-lav ml-1 mt-3 p-3 duration-300 rounded-sm hover:bg-russ  focus:ring-4 focus:ring-lav"
                  onClick={this.cancelEvent}
                >
                  Cancel
                </button>
              )}
              {(c.status === "active" || c.status === "canceled") && (
                <button
                  className="block text-white float-right bg-lav ml-1 mt-3 p-3 duration-300 rounded-sm hover:bg-russ  focus:ring-4 focus:ring-lav"
                  onClick={this.unpublishEvent}
                >
                  {c.status === "active" ? "Unpublish" : "To Draft"}
                </button>
              )}
              <h3 className="font-medium text-2xl text-left text-gray-500">Summary</h3>
              <ul className="list-disc ml-8 mt-3">
                <li className="">
                  Status: <strong>{c.status}</strong>
                </li>
                <li className="">
                  Type: <strong>{c.type}</strong>
                </li>
                <li className="">
                  Visibility: <strong>{c.visibility}</strong>
                </li>
                {c.duration && <li className="">Duration: {c.duration} min</li>}
                {/* <li className="">
                  Reminders 30 sent: <strong>{`${c.reminders30_sent}`}</strong>
                </li>
                <li className="">
                  Reminders 5 sent: <strong>{`${c.reminders5_sent}`}</strong>
                </li> */}
                <li className="">From: {halps.dshort(c.start_time)}</li>
                <li className="">To: {halps.dshort(c.end_time)}</li>
                <li className="">
                  <a
                    rel="noreferrer"
                    href={`${halps.webappBase()}/events/${c.id}`}
                    target="_blank"
                    className="underline cursor-pointer"
                  >
                    View in webapp
                  </a>
                </li>
                {c.parent_id && (
                  <li className="">
                    <a
                      rel="noreferrer"
                      href={`/events/${c.parent_id}`}
                      target="_blank"
                      className="underline cursor-pointer"
                    >
                      Parent Event
                    </a>
                  </li>
                )}
                {/* <li>
                  <span onClick={this.rollSA} className="underline cursor-pointer">
                    Roll shareable asset
                  </span>
                </li> */}
              </ul>
              {/* <form className="mt-3" action="" onSubmit={this.bookUser}>
                <div className="text-sm mt-2">
                  Book user to this event (<strong>won't send confirmation email</strong>)
                </div>

                <div className="flex">
                  <input
                    type="text"
                    id="book_user"
                    name="book_user"
                    className="bg-f1 flex-grow rounded-md w-full focus:ring-4 focus:ring-lav"
                    placeholder="Email or user id"
                    value={this.state.book_user}
                    onChange={this.formInputUpdate}
                    required
                  />

                  <input
                    className="flex-none text-center mx-2 text-white bg-lav p-3 duration-300 rounded-sm hover:bg-russ focus:ring-4 focus:ring-lav"
                    type="submit"
                    value="Book"
                  />
                </div>
              </form> */}
            </div>
          </div>
          <div className="w-full">
            {c.image_link ? (
              <img className="max-h-96 inline-block mx-auto" src={c.image_link} />
            ) : (
              <X />
            )}
          </div>
        </div>

        <h2 className="text-2xl mt-4">Bookings</h2>
        <p>
          {Object.keys(bks)
            .sort()
            .map((k) => (
              <span className="mr-4">{`${k}: ${bks[k]}`}</span>
            ))}
        </p>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-2">Date Booked</th>
              <th className="border p-2">Email</th>
              <th className="border p-2">Name</th>
              <th className="border p-2">Joined</th>
              <th className="border p-2">Status</th>
              <th className="border p-2">Trial Ends</th>
              {/* <th className="border p-2"># Joined</th> */}
              {/* <th className="border p-2">Bk</th> */}
              {/* <th className="border p-2">ODV</th> */}
            </tr>
          </thead>
          <tbody>
            {this.state.bookings.map((b) => {
              const u = gu(b.user_id);
              return (
                <tr key={b.id} className="hover:bg-gray-300">
                  <td className="border p-2">{halps.dDateHour(b.created)}</td>
                  <td className="border p-2">
                    <Link to={`/users/${b.user_id}`} className="underline text-blue-500">
                      {" "}
                      {u.email}
                    </Link>
                  </td>
                  <td className="border p-2">{`${u.first_name} ${u.last_name.slice(0, 10)}`}</td>
                  <td className="border p-2">
                    {`${b.joined}`}{" "}
                    <span
                      onClick={() => this.joinToggle(b.id)}
                      className="inline-block text-russ bg-beau ml-2 px-2 py-1 rounded-md shadow-md cursor-pointer"
                    >
                      {b.joined ? "Remove" : "Check in"}
                    </span>
                  </td>
                  {/* <td className="border p-2">{this.reminderSent(b)}</td> */}
                  <td className="border p-2">{b.status}</td>
                  <td className="border p-2">
                    {u.status2.startsWith("trial") ? halps.dayshort(u.trial_ends) : "-"}
                  </td>
                  {/* <td className="border p-2">{`${u.stats.total_joined_events}`}</td> */}
                  {/* <td className="border p-2">{`${u.stats.total_booked_events}`}</td> */}
                  {/* <td className="border p-2">{`${u.stats.total_od_views}`}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Edit Event</h3>
            <div className="mt-2 text-sm underline cursor-pointer">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/document/d/1rPw6Xaxhz3XUu4pXU6zJhyaVmjkYFFAo4ejc8mukS80/edit#heading=h.92lvdmdd1tfd"
              >
                Please read more about event data and rendering here
              </a>
            </div>
            <form className="mt-3" action="" onSubmit={this.formSubmit}>
              <div className="text-sm">Visibility</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="visibility"
                name="visibility"
                onChange={this.formInputUpdate}
                value={this.state.visibility}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["visibility"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["visibility"]
                  ? this.state.fieldErrors["visibility"].message
                  : ""}
              </div>

              <div className="text-sm">Start Time</div>
              <DatePicker
                className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
                selected={this.state.start_time}
                onChange={(date) => this.setStartTime(date)}
                dateFormat="yyyy/MM/dd HH:mm"
                placeholderText="YYYY/MM/DD HH:mm"
                showTimeSelect
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["start_time"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["start_time"]
                  ? this.state.fieldErrors["start_time"].message
                  : ""}
              </div>

              <div className="text-sm">End Time</div>
              <DatePicker
                className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
                selected={this.state.end_time}
                onChange={(date) => this.setEndTime(date)}
                dateFormat="yyyy/MM/dd HH:mm"
                placeholderText="YYYY/MM/DD HH:mm"
                showTimeSelect
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["end_time"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["end_time"]
                  ? this.state.fieldErrors["end_time"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Teachers</div>
              <Select
                className="bg-f1  rounded-md w-full focus:ring-4 focus:ring-lav"
                options={this.state.all_teachers}
                isMulti="true"
                id="teacher_ids"
                name="teacher_ids"
                onChange={this.formInputSelectTeacher}
                value={this.state.teachers}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["teacher_ids"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["teacher_ids"]
                  ? this.state.fieldErrors["teacher_ids"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Title</div>
              <input
                type="text"
                id="title_a"
                name="title_a"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Title"
                value={this.state.title_a}
                onChange={this.formInputUpdate}
                required
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["title_a"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["title_a"] ? this.state.fieldErrors["title_a"].message : ""}
              </div>

              {/* <div className="text-sm mt-2">Title B</div>
              <input
                type="text"
                id="title_b"
                name="title_b"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Title B (for A/B testing)"
                value={this.state.title_b}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["title_b"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["title_b"] ? this.state.fieldErrors["title_b"].message : ""}
              </div> */}

              <div className="text-sm mt-2">Short Description</div>
              <input
                type="text"
                id="short_description_a"
                name="short_description_a"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Short Description"
                value={this.state.short_description_a}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["short_description_a"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["short_description_a"]
                  ? this.state.fieldErrors["short_description_a"].message
                  : ""}
              </div>

              {/* <div className="text-sm mt-2">Short Description B</div>
              <input
                type="text"
                id="short_description_b"
                name="short_description_b"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Short Description"
                value={this.state.short_description_b}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["short_description_b"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["short_description_b"]
                  ? this.state.fieldErrors["short_description_b"].message
                  : ""}
              </div> */}

              <div className="text-sm mt-2">Comment Thread ID</div>
              <input
                type="text"
                id="comment_thread_id"
                name="comment_thread_id"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Comment Thread ID"
                value={this.state.comment_thread_id}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["comment_thread_id"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["comment_thread_id"]
                  ? this.state.fieldErrors["comment_thread_id"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Long Description</div>
              <textarea
                id="long_description"
                name="long_description"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                onChange={this.formInputUpdate}
                value={this.state.long_description}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["long_description"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["long_description"]
                  ? this.state.fieldErrors["long_description"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Image Link</div>
              <input
                type="url"
                id="image_link"
                name="image_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                value={this.state.image_link}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["image_link"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["image_link"]
                  ? this.state.fieldErrors["image_link"].message
                  : ""}
              </div>
              {this.validImageLink(this.state.image_link) && (
                <img className="p-4 max-h-40 inline-block mx-auto" src={this.state.image_link} />
              )}

              <div className="text-sm mt-2">Media Link</div>
              <input
                type="url"
                id="media_link"
                name="media_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                value={this.state.media_link}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["media_link"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["media_link"]
                  ? this.state.fieldErrors["media_link"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Content</div>
              <div style={{ background: "white", padding: "5px" }}>
                <Editor
                  editorState={this.state.contentState}
                  onEditorStateChange={this.onContentChange}
                />
              </div>
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["content"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["content"] ? this.state.fieldErrors["content"].message : ""}
              </div>

              <div className="text-sm mt-2">Klaviyo Event Name</div>
              <input
                type="text"
                id="klaviyo_event_name"
                name="klaviyo_event_name"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="klaviyo_event_name"
                value={this.state.klaviyo_event_name}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["klaviyo_event_name"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["klaviyo_event_name"]
                  ? this.state.fieldErrors["klaviyo_event_name"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Total Capacity</div>
              <input
                type="text"
                id="total_capacity"
                name="total_capacity"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="total_capacity"
                value={this.state.total_capacity}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["total_capacity"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["total_capacity"]
                  ? this.state.fieldErrors["total_capacity"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Price (in pennies)</div>
              <input
                type="text"
                id="price"
                name="price"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="price"
                value={this.state.price}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["price"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["price"] ? this.state.fieldErrors["price"].message : ""}
              </div>

              <div className="text-sm mt-2">Coupon Price (in pennies)</div>
              <input
                type="text"
                id="coupon_price"
                name="coupon_price"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="coupon_price"
                value={this.state.coupon_price}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["coupon_price"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["coupon_price"]
                  ? this.state.fieldErrors["coupon_price"].message
                  : ""}
              </div>

              <div className="text-sm mt-2">Suggested Donation Price (in pennies)</div>
              <p className="text-xs italic ml-3">
                If this field is &gt; 0 we'll allow sliding scale donations.{" "}
                <a
                  className="underline"
                  href="https://docs.google.com/document/d/1ldr3ijkANe-xe-xu6LhVmEtlfF_RCIMBl3vyH_cwxtw/edit#"
                  target="_blank"
                  rel="noreferrer"
                >
                  See spec for details
                </a>
              </p>
              <input
                type="text"
                id="suggested_donation_price"
                name="suggested_donation_price"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav mt-2"
                placeholder="suggested_donation_price"
                value={this.state.suggested_donation_price}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["suggested_donation_price"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["suggested_donation_price"]
                  ? this.state.fieldErrors["suggested_donation_price"].message
                  : ""}
              </div>

              <div className="text-lg font-medium mt-4">
                *** Address fields - Leave blank for virtual events
              </div>

              <div className="text-sm mt-2">Address 1</div>
              <input
                type="text"
                id="addr1"
                name="addr1"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Address 1"
                value={this.state.addr1}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["addr1"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["addr1"] ? this.state.fieldErrors["addr1"].message : ""}
              </div>

              <div className="text-sm mt-2">Address 2</div>
              <input
                type="text"
                id="addr2"
                name="addr2"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Address 2"
                value={this.state.addr2}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["addr2"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["addr2"] ? this.state.fieldErrors["addr2"].message : ""}
              </div>

              <div className="text-sm mt-2">City</div>
              <input
                type="text"
                id="city"
                name="city"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="City"
                value={this.state.city}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${this.state.fieldErrors["city"] ? "is-invalid" : ""}`}
              >
                {this.state.fieldErrors["city"] ? this.state.fieldErrors["city"].message : ""}
              </div>

              <div className="text-sm mt-2">Country</div>
              <input
                type="text"
                id="country"
                name="country"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="country"
                value={this.state.country}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["country"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["country"] ? this.state.fieldErrors["country"].message : ""}
              </div>

              <div className="text-sm mt-2">Phone</div>
              <input
                type="text"
                id="phone"
                name="phone"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="phone"
                value={this.state.phone}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["phone"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["phone"] ? this.state.fieldErrors["phone"].message : ""}
              </div>

              <div className="text-sm mt-2">State</div>
              <input
                type="text"
                id="state"
                name="state"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="state"
                value={this.state.state}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${
                  this.state.fieldErrors["state"] ? "is-invalid" : ""
                }`}
              >
                {this.state.fieldErrors["state"] ? this.state.fieldErrors["state"].message : ""}
              </div>

              <div className="text-sm mt-2">Postal Code (zip)</div>
              <input
                type="text"
                id="zip"
                name="zip"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="zip"
                value={this.state.zip}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${this.state.fieldErrors["zip"] ? "is-invalid" : ""}`}
              >
                {this.state.fieldErrors["zip"] ? this.state.fieldErrors["zip"].message : ""}
              </div>

              <div className="text-sm mt-2">Geo Lat</div>
              <input
                type="text"
                id="lat"
                name="lat"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="lat"
                value={this.state.lat}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${this.state.fieldErrors["lat"] ? "is-invalid" : ""}`}
              >
                {this.state.fieldErrors["lat"] ? this.state.fieldErrors["lat"].message : ""}
              </div>

              <div className="text-sm mt-2">Geo Long</div>
              <input
                type="text"
                id="long"
                name="long"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="long"
                value={this.state.long}
                onChange={this.formInputUpdate}
              />
              <div
                className={`invalid-feedback ${this.state.fieldErrors["long"] ? "is-invalid" : ""}`}
              >
                {this.state.fieldErrors["long"] ? this.state.fieldErrors["long"].message : ""}
              </div>

              <button
                disabled={this.state.submitDisabled ? "true" : ""}
                type="submit"
                className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
              >
                {this.state.submitDisabled ? <Spinline /> : "Save Changes"}
              </button>
            </form>
          </div>
        </div>

        {this.state.event.support_zoom && this.state.zoomLoadMsg && (
          <div className="w-full xl:w-6/12 mt-4">
            <h3 className="font-medium text-2xl mt-5 mb-5 text-center">{this.state.zoomLoadMsg}</h3>
          </div>
        )}

        {this.state.event.support_zoom && this.state.zoomInfoLoading && <Spinline />}

        {this.state.event.support_zoom && !this.state.zoomLoadMsg && !this.state.zoomInfoLoading && (
          <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
            <div className="p-4 md:p-8">
              <h3 className="font-medium text-2xl text-left text-gray-500">Edit Zoom Meeting</h3>
              <div className="mt-3">
                <a
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                  href={`https://us02web.zoom.us/meeting/${this.state.zoomMeetingID}`}
                >
                  View in zoom dashboard
                </a>
              </div>
              <form className="mt-3" action="" onSubmit={this.zoomFormSubmit}>
                <div className="text-sm mt-2">Description</div>
                <input
                  type="text"
                  id="zoom_description"
                  name="zoom_description"
                  className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                  placeholder="Description"
                  value={this.state.zoom_description}
                  onChange={this.formInputUpdate}
                />

                <button
                  disabled={this.state.submitDisabled ? "true" : ""}
                  type="submit"
                  className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
                >
                  {this.state.submitDisabled ? <Spinline /> : "Save Changes"}
                </button>
              </form>
            </div>
          </div>
        )}

        <h2 className="mt-4 text-2xl font-medium">All Fields</h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(c)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${c[k]}`}</td>
              </tr>
            ))}
        </table>

        {this.state.event.support_zoom && (
          <h2 className="mt-4 text-2xl font-medium">Zoom Meeting Info</h2>
        )}

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {this.state.event.support_zoom && !isProd() && this.state.zoomInfoLoading && <Spinline />}
          {this.state.event.support_zoom &&
            !isProd() &&
            !this.state.zoomInfoLoading &&
            !this.state.zoomAPIModel &&
            "Zoom meeting is not created yet, use zoom form above to create one"}
          {this.state.event.support_zoom &&
            !isProd() &&
            !this.state.zoomInfoLoading &&
            this.state.zoomAPIModel &&
            Object.keys(this.state.zoomAPIModel)
              .sort()
              .map((k) => (
                <tr key={k}>
                  <th className="border border-gray-400 p-3">{k}</th>
                  <td className="border border-gray-400 p-3">
                    {typeof this.state.zoomAPIModel[k] === "object" &&
                    this.state.zoomAPIModel[k] !== null &&
                    k !== "settings"
                      ? JSON.stringify(this.state.zoomAPIModel[k])
                      : `${this.state.zoomAPIModel[k]}`}
                  </td>
                </tr>
              ))}
          {this.state.event.support_zoom &&
            !isProd() &&
            !this.state.zoomInfoLoading &&
            this.state.zoomAPIModel &&
            this.state.zoomAPIModel["settings"] &&
            Object.keys(this.state.zoomAPIModel["settings"])
              .sort()
              .map((k) => (
                <tr key={k}>
                  <th className="border border-gray-400 p-3">settings.{k}</th>
                  <td className="border border-gray-400 p-3">
                    {typeof (
                      this.state.zoomAPIModel["settings"][k] === "object" &&
                      this.state.zoomAPIModel[k] !== null
                    )
                      ? JSON.stringify(this.state.zoomAPIModel["settings"][k])
                      : `${this.state.zoomAPIModel["settings"][k]}`}
                  </td>
                </tr>
              ))}
        </table>
      </div>
    );
  }
}

function X(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="329pt"
      viewBox="0 0 329.26933 329"
      width="329pt"
    >
      <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
    </svg>
  );
}
