import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import axios from "axios";

export default class BookingPage extends Component {
  state = {
    loading: true,
    booking: {},
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/bookings/${this.props.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          booking: resp.data.booking,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    const b = this.state.booking;
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Booking {this.props.id}</h2>

        <h2 id="all_fields" className="mt-4 text-2xl font-medium">
          All Booking Fields
        </h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(b)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${b[k]}`}</td>
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
