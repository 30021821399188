import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import axios from "axios";

export default class BillPlansPage extends Component {
  state = {
    loading: true,
    plans: [],
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/bill/plans`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          plans: resp.data.plans,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Billing Plans</h2>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">ID</th>
              <th className="border p-4">Title</th>
              <th className="border p-4">Desc</th>
              <th className="border p-4">Blue box text</th>
              <th className="border p-4">Stripe Dev PriceID</th>
              <th className="border p-4">Stripe Prod PriceID</th>
              <th className="border p-4">Price</th>
              <th className="border p-4">Months</th>
              <th className="border p-4">Month Price</th>
            </tr>
          </thead>
          <tbody>
            {this.state.plans.map((p) => {
              return (
                <tr key={p.id} className="hover:bg-gray-300">
                  <td className="border p-4">{p.id}</td>
                  <td className="border p-4">{p.title}</td>
                  <td className="border p-4">{p.desc}</td>
                  <td className="border p-4">{p.blue_box_text}</td>
                  <td className="border p-4">{p.stripe_price_dev}</td>
                  <td className="border p-4">{p.stripe_price_prod}</td>
                  <td className="border p-4">{p.interval_price}</td>
                  <td className="border p-4">{p.interval_months}</td>
                  <td className="border p-4">{p.monthly_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
