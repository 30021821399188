import React, { Component, useState } from "react";
import { Link, Router, navigate } from "@reach/router";
import { Popover } from "@headlessui/react";
import axios from "axios";

import { getAuth, signInWithCustomToken } from "firebase/auth";
import * as halps from "./common/Halps";
import HomePage from "./Home";
import UsersPage from "./Users";
import UserPage from "./User";
import ClassesPage from "./Classes";
import ClassPage from "./Class";
import EventsPage from "./Events";
import EventPage from "./Event";
import OnDemandsPage from "./OnDemands";
import OnDemandPage from "./OnDemand";
import PastClassesPage from "./PastClasses";
import PastEventsPage from "./PastEvents";
import LogPage from "./LogPage";
import ContactPage from "./ContactPage";
import NotsPage from "./Nots";
import CommentsPage from "./Comments";
import BookingPage from "./Booking";
import * as net from "./common/Net";
import BillPlansPage from "./BillPlans";

class App extends Component {
  state = {
    loading: true,
    needsLogin: false,
    error: "",
    user: {},
    fam: {},
  };

  componentDidMount() {
    const auth = getAuth();
    halps.handleUserState().then((user) => {
      if (!user) {
        this.setState({ needsLogin: true, loading: false });
      } else if (user.error) {
        this.setState({ error: user.error, loading: false });
      } else {
        // We're logged in
        this.setState({ needsLogin: false, loading: false, user });
        // Need to also handle firebase auth::
        if (user?.firebase_token) {
          signInWithCustomToken(auth, user?.firebase_token)
            .then((userCredential) => {})
            .catch((error) => {
              console.log("error", error);
            });
        }
      }
    });
  }

  constructor(props) {
    super();
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    if (this.state.error) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Error</h3>
          <p className="font-medium text-lg mt-5 mb-5 text-center">{this.state.error}</p>
          <div
            className="font-medium text-lg mt-5 mb-5 text-center underline cursor-pointer"
            onClick={() => halps.logout()}
          >
            Logout
          </div>
        </>
      );
    }
    if (this.state.needsLogin) {
      return (
        <>
          <LoginWithGoogle />
          {/* <RegForm /> */}
        </>
      );
    }
    return (
      <>
        <Header user={this.state.user} />
        <Router>
          <HomePage path="/" user={this.state.user} />
          <UsersPage path="/users" user={this.state.user} />
          <UserPage path="/users/:id" user={this.state.user} />
          <ClassesPage path="/classes" user={this.state.user} />
          <EventsPage path="/events" user={this.state.user} />
          <EventPage path="/events/:id" user={this.state.user} />
          <PastClassesPage path="/past-classes" user={this.state.user} />
          <PastEventsPage path="/past-events" user={this.state.user} />
          <ClassPage path="/classes/:id" user={this.state.user} />
          <OnDemandsPage path="/on-demand" user={this.state.user} />
          <OnDemandPage path="/on-demand/:id" user={this.state.user} />
          <BookingPage path="/bookings/:id" user={this.state.user} />

          {/* *** ETC PAGES *** hacky nav! */}
          <LogPage path="/logs" user={this.state.user} />
          <ContactPage path="/contact" user={this.state.user} />
          <NotsPage path="/nots" user={this.state.user} />
          <CommentsPage path="/comms" user={this.state.user} />
          <BillPlansPage path="/plans" user={this.state.user} />
        </Router>
      </>
    );
  }
}

// TODO BUILD ME!!!!! Display a button that will hard redirect them to the configured
// api endpoint /users/oauth/google/start ! boom!
function LoginWithGoogle() {
  return (
    <div className="bg-gray-200 p-2 text-center h-screen flex flex-wrap justify-center content-center">
      <div className="bg-white p-12">
        <a
          href={halps.getGoogleOauthRedirect()}
          className="text-white bg-red-600 p-3 duration-300 rounded-lg hover:bg-black"
        >
          Login with Google
        </a>
      </div>
    </div>
  );
}

// Comes from: https://tailwindcomponents.com/component/responsive-header
// Original has an example of right-floated element
function Header(props) {
  return (
    <nav className="flex items-center justify-between flex-wrap bg-lav p-3 text-lg text-white">
      <div className="flex items-center flex-no-shrink  font-bold mr-7">
        <Link to="/" className="">
          Chorus Admin
        </Link>
      </div>

      <div className="block flex-grow md:flex md:items-center md:w-auto">
        <div className="md:flex-grow">
          <Link to="/users" className="block mt-4 md:inline-block md:mt-0 hover:text-russ mr-7 ">
            Users
          </Link>
          <Link to="/classes" className="block mt-4 md:inline-block md:mt-0 hover:text-russ mr-7 ">
            Classes
          </Link>
          <Link to="/events" className="block mt-4 md:inline-block md:mt-0 hover:text-russ mr-7 ">
            Events
          </Link>
          <Link
            to="/on-demand"
            className="block mt-4 md:inline-block md:mt-0  hover:text-russ mr-7 "
          >
            On-Demand
          </Link>
          <EtcMenu />
          <UserSearch user={props.user} />
        </div>
      </div>
    </nav>
  );
}

function UserSearch(props) {
  let [email, setEmail] = useState("");

  const findUser = (e) => {
    e.preventDefault();
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/users/find?email=${encodeURIComponent(email)}`,
      headers: { Authorization: "Bearer " + props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        const u = resp.data.user;
        const us = resp.data.users;
        if (us) {
          // Doing a search
          setEmail("");
          window.location.href = `/users?search=${email}`;
        } else if (u) {
          setEmail("");
          window.location.href = `/users/${u.id}`;
        } else {
          halps.alertError("Not found", "No user found for that email");
        }
      })
      .catch(net.handleError2);
  };

  const handleInputChange = (event) => {
    if (event.target.name !== "email") {
      halps.alertError("Error", `Invalid event target name ${event.target.name}`);
      return;
    }
    console.log(event.target.value);
    setEmail(event.target.value);
  };

  return (
    <span className="inline-block ml-6 ">
      <form onSubmit={findUser}>
        <input
          id="email"
          name="email"
          type="text"
          onChange={handleInputChange}
          value={email}
          className="px-2 py-1 rounded-xl text-gray-500"
          placeholder="Email or user id"
        />{" "}
        <button type="submit" className="bg-beau text-gray-900 rounded-xl px-2 py-1">
          Find User
        </button>
      </form>
    </span>
  );
}

function EtcMenu() {
  return (
    <Popover className="inline-block">
      <Popover.Button>Etc</Popover.Button>

      <Popover.Panel className="absolute z-10">
        <div className="grid grid-cols-1 bg-lav shadow-lg p-4">
          <Link to="/comms" className="hover:text-russ">
            Comments
          </Link>
          <Link to="/contact" className="hover:text-russ">
            Contact
          </Link>
          <Link to="/logs" className="hover:text-russ">
            Logs
          </Link>
          <Link to="/nots" className="hover:text-russ">
            Notifications
          </Link>
          <Link to="/plans" className="hover:text-russ">
            Billing Plans
          </Link>
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default App;
