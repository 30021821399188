import React, { Component } from "react";
import { Link, navigate, Router } from "@reach/router";
import axios from "axios";

import * as halps from "./common/Halps";
import * as net from "./common/Net";

export default class OnDemandsPage extends Component {
  state = {
    loading: true,
    onDemands: [],
    class_id: "",
  };

  componentDidMount() {
    net.getOnDemands(this.props.user.token).then((data) => {
      this.setState({ loading: false, onDemands: data.ondemands });
    });
  }

  constructor(props) {
    super();
  }

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  formSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/on-demands`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        class_id: this.state.class_id,
      },
    })
      .then((resp) => {
        navigate(`/on-demand/${resp.data.ondemand.id}`);
      })
      .catch(net.handleError2);
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl mb-4">On-Demand</h2>

        <div className="w-full md:w-4/12 bg-beau shadow-md p-5 mt-3">
          <div className="py-8 px-8 rounded-xl">
            <h2 className="text-lg">Add New On Demand</h2>
            <div className="mt-2 text-sm underline cursor-pointer">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/document/d/1bn-_bfdhxXhftLDDouE9P-9YBUWNpDY7oSag7Zcugsk/edit#heading=h.bwjbgs8yznjz"
              >
                Instructions for working with on demand videos
              </a>
            </div>
            <form action="" className="mt-4" onSubmit={this.formSubmit}>
              <div className="text-sm mt-2">Class ID</div>
              <input
                type="text"
                id="class_id"
                name="class_id"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Class ID or empty"
                value={this.state.class_id}
                onChange={this.formInputUpdate}
              />

              <input
                className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
                type="submit"
                value="Add New On Demand"
              />
            </form>
          </div>
        </div>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Recorded Time</th>
              <th className="border p-4">Teacher</th>
              <th className="border p-4">Status</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Duration</th>
              <th className="border p-4">Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {this.state.onDemands.map((od) => {
              return (
                <tr key={od.id} className="hover:bg-gray-300">
                  <td className="border p-4 underline cursor-pointer">
                    <Link to={`/on-demand/${od.id}`}>{halps.dayshort(od.recorded_time)}</Link>
                  </td>
                  <td className="border p-4 uppercase">{od.teacher_id}</td>
                  <td className={od.status !== "active" ? "border p-4 text-red-600" : "border p-4"}>
                    {od.status}
                  </td>
                  <td className="border p-4">{od.type}</td>
                  <td className="border p-4">{od.duration}</td>
                  <td className="border p-4">{halps.dayshort(od.updated)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
