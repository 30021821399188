import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link } from "@reach/router";
import axios from "axios";

export default class LogPage extends Component {
  state = {
    loading: true,
    logs: [],
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/logs`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          logs: resp.data.logs,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Admin Logs</h2>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Created</th>
              <th className="border p-4">Admin User ID</th>
              <th className="border p-4">Admin Email</th>
              <th className="border p-4">Action Path</th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map((l) => {
              return (
                <tr key={l.id} className="hover:bg-gray-300">
                  <td className="border p-4">{halps.dshort(l.created)}</td>
                  <td className="border p-4">{l.user_id}</td>
                  <td className="border p-4">{l.obj1_id}</td>
                  <td className="border p-4">{l.type2}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
