import React, { Component, useState } from "react";
import { Link, navigate } from "@reach/router";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import * as halps from "./common/Halps";
import * as net from "./common/Net";
import * as icons from "./common/Icons";

export default class EventsPage extends Component {
  state = {
    loading: true,
    events: [],
    showForm: false,
    title: "",
    startTime: null,
    endTime: null,
    type: "virtual",
    all_teachers: [],
    teachers: [],
    fieldErrors: {},
  };

  componentDidMount() {
    net.getEvents(this.props.user.token).then((data) => {
      this.setState({ loading: false, events: data.events, all_teachers: data.teachers });
    });
  }

  constructor(props) {
    super();
  }

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  formInputSelectTeacher = (teachers) => {
    if (!teachers) {
      return;
    }
    this.setState({
      teachers,
    });
  };

  cloneEventAction = (eventID, title) => {
    return (event) => {
      confirmAlert({
        message: `Do you really want to clone article "${title}?"`,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.cloneEvent(eventID),
          },
          {
            label: "No",
          },
        ],
      });
    };
  };

  cloneEvent = async (eventID) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events/${eventID}/clone`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
    })
      .then((resp) => {
        // On success navigate to the new page
        navigate(`/events/${resp.data.event.id}`);
      })
      .catch((error) => {
        net.handleError2(error);
      });
  };

  handleSubmit = async (event, setSpin) => {
    setSpin(true);
    event.preventDefault();
    if (!this.state.startTime) {
      setSpin(false);
      halps.alertError("", "Please choose a start time");
      return;
    }

    if (!this.state.endTime) {
      setSpin(false);
      halps.alertError("", "Please choose an end time");
      return;
    }

    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/events`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        title: this.state.title,
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        type: this.state.type,
        teacher_ids: this.state.teachers.map((t) => {
          return t.value;
        }),
      },
    })
      .then((resp) => {
        setSpin(false);
        // On success navigate to the new page
        navigate(`/events/${resp.data.newEvent.id}`);
      })
      .catch((error) => {
        setSpin(false);
        let apiErr = net.handleError2(error);
        this.setErrorsState(apiErr);
      });
  };

  setErrorsState = (error) => {
    let fieldErrors = {};
    if (error && error.meta) {
      fieldErrors = error.meta;
    }
    this.setState({
      fieldErrors,
    });
  };

  // Standard method of show/hide form for event and on demand pages
  showForm = () => {
    this.setState((s, p) => ({ showForm: !s.showForm }));
  };

  setStartTime = (startTime) => {
    this.setState({ startTime });
    console.log(startTime);
    console.log(halps.dateToDay(startTime));
  };

  setEndTime = (endTime) => {
    this.setState({ endTime });
    console.log(endTime);
    console.log(halps.dateToDay(endTime));
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Live Events</h2>
        <div className="mt-2">
          <Link to="/past-events" className="text-sm underline cursor-pointer">
            View past events
          </Link>
        </div>
        <h3 className="mt-3">
          Add New Event{" "}
          <icons.plus cn={"h-8 w-8 inline-block cursor-pointer"} clack={this.showForm} />{" "}
        </h3>
        {this.state.showForm ? (
          <AddEventForm
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            title={this.state.title}
            type={this.state.type}
            teacher_ids={this.state.teacher_ids}
            all_teachers={this.state.all_teachers.map((t) => {
              return { value: t.id, label: t.first_name };
            })}
            teachers={this.state.teachers}
            formInputUpdate={this.formInputUpdate}
            formInputSelectTeacher={this.formInputSelectTeacher}
            fieldErrors={this.state.fieldErrors}
            formSubmit={this.handleSubmit}
            setStartTime={this.setStartTime}
            setEndTime={this.setEndTime}
          />
        ) : (
          ""
        )}
        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Title</th>
              <th className="border p-4">Start Time</th>
              <th className="border p-4">Teachers</th>
              <th className="border p-4">Booked</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Status</th>
              <th className="border p-4"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.events.map((c) => {
              return (
                <tr key={c.id} className="hover:bg-gray-300">
                  <td className="border p-4 underline cursor-pointer">
                    <Link to={`/events/${c.id}`}>{c.title_a}</Link>
                  </td>
                  <td className="border p-4">{halps.dshort(c.start_time)}</td>
                  <td className="border p-4 uppercase">
                    {c.teacher_ids.map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(", ")}
                  </td>
                  <td className="border p-4">{c.current_capacity}</td>
                  <td className="border p-4">{c.type}</td>
                  <td className="border p-4">{c.status}</td>
                  <td className="border p-4">
                    <button
                      className="block text-white float-right bg-lav ml-1 mt-3 p-3 duration-300 rounded-sm hover:bg-russ  focus:ring-4 focus:ring-lav"
                      onClick={this.cloneEventAction(c.id, c.title_a)}
                    >
                      Clone
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function AddEventForm(props) {
  let [formDisabled, setFormDisabled] = useState(false);

  const setSpinAndDisabled = (val) => {
    setFormDisabled(val);
  };

  const submitForm = async (event) => {
    await props.formSubmit(event, setSpinAndDisabled);
  };

  return (
    <div className="w-full md:w-4/12 bg-beau shadow-md p-5 mt-3">
      <div className="py-8 px-8 rounded-xl">
        <h2 className="text-lg">Add A New Event</h2>
        <form action="" className="mt-4" onSubmit={submitForm}>
          <div className="text-sm mt-2">Title</div>
          <input
            type="text"
            id="title"
            name="title"
            className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
            value={props.title}
            onChange={props.formInputUpdate}
            required
          />
          <div className={`invalid-feedback ${props.fieldErrors["title"] ? "is-invalid" : ""}`}>
            {props.fieldErrors["title"] ? props.fieldErrors["title"].message : ""}
          </div>

          <div className="text-sm mt-2">Start Time</div>
          <DatePicker
            className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
            selected={props.startTime}
            onChange={(date) => props.setStartTime(date)}
            dateFormat="yyyy/MM/dd HH:mm"
            placeholderText="YYYY/MM/DD HH:mm"
            showTimeSelect
          />
          <div
            className={`invalid-feedback ${props.fieldErrors["start_time"] ? "is-invalid" : ""}`}
          >
            {props.fieldErrors["start_time"] ? props.fieldErrors["start_time"].message : ""}
          </div>

          <div className="text-sm mt-2">End Time</div>
          <DatePicker
            className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
            selected={props.endTime}
            onChange={(date) => props.setEndTime(date)}
            dateFormat="yyyy/MM/dd HH:mm"
            placeholderText="YYYY/MM/DD HH:mm"
            showTimeSelect
          />
          <div className={`invalid-feedback ${props.fieldErrors["end_time"] ? "is-invalid" : ""}`}>
            {props.fieldErrors["end_time"] ? props.fieldErrors["end_time"].message : ""}
          </div>

          <div className="text-sm mt-2">Teachers</div>
          <Select
            className="bg-f1  rounded-md w-full focus:ring-4 focus:ring-lav"
            options={props.all_teachers}
            isMulti="true"
            id="teacher_ids"
            name="teacher_ids"
            onChange={props.formInputSelectTeacher}
            value={props.teachers}
          />
          <div
            className={`invalid-feedback ${props.fieldErrors["teacher_ids"] ? "is-invalid" : ""}`}
          >
            {props.fieldErrors["teacher_ids"] ? props.fieldErrors["teacher_ids"].message : ""}
          </div>

          <div className="text-sm mt-2">Type</div>
          <select
            className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
            id="type"
            name="type"
            onChange={props.formInputUpdate}
            value={props.type}
          >
            <option value="virtual">Virtual</option>
            <option value="in_person">In Person</option>
            <option value="media">Media</option>
          </select>
          <div className={`invalid-feedback ${props.fieldErrors["type"] ? "is-invalid" : ""}`}>
            {props.fieldErrors["type"] ? props.fieldErrors["type"].message : ""}
          </div>

          <button
            disabled={formDisabled ? "true" : ""}
            type="submit"
            className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
          >
            {formDisabled ? <icons.Spinline /> : "Add New Event"}
          </button>
        </form>
      </div>
    </div>
  );
}
