import React, { Component } from "react";
import { firestoreDb } from "./common/Firebase";
import { query, limit, orderBy, onSnapshot, collection } from "firebase/firestore";
import { commentsCollection, repliesCollection } from "./common/Consts";
import { Link } from "@reach/router";
import { dDateHour } from "./common/Halps";

export default class CommentsPage extends Component {
  state = {
    loading: true,
    comments: [],
    replies: [],
    unsub: null,
    unsub2: null,
  };

  componentDidMount() {
    this.subComments();
    this.subReplies();
  }
  componentWillUnmount() {
    console.log("Comments page unmounting");
    if (this.state.unsub) {
      console.log("GOING to unsub");
      this.state.unsub(); // unsub from comment observation
      this.state.unsub2(); // unsub from comment observation
    }
  }

  constructor(props) {
    super();
  }

  // Taken from webapp:/src/hooks/Comments.js~84
  // This will call
  subComments = () => {
    const q = query(
      collection(firestoreDb, commentsCollection),
      orderBy("updated_at", "desc"),
      limit(100)
    );
    const unsub = onSnapshot(
      q,
      async (querySnapshot) => {
        let _docs = [];
        for (let i in querySnapshot.docs) {
          const _doc = querySnapshot.docs[i];
          // Looks like here we have the actual comments data
          let cmnt = _doc.data();
          let commentId = _doc.id;

          cmnt = {
            ...cmnt,
            id: commentId,
          };

          _docs.push(cmnt);
        }

        const _docsData = await Promise.all(_docs);

        this.setState({
          loading: false,
          comments: _docsData,
        });
      },
      (error) => {
        alert("Comments failed? Check console");
        console.log(error);
      }
    );
    this.setState({ unsub });
  };

  subReplies = () => {
    const q = query(
      collection(firestoreDb, repliesCollection),
      orderBy("updated_at", "desc"),
      limit(100)
    );
    const unsub2 = onSnapshot(
      q,
      async (querySnapshot) => {
        let _docs = [];
        for (let i in querySnapshot.docs) {
          const _doc = querySnapshot.docs[i];
          // Looks like here we have the actual comments data
          let reply = _doc.data();
          let replyId = _doc.id;

          reply = {
            ...reply,
            id: replyId,
          };

          _docs.push(reply);
        }

        const _docsData = await Promise.all(_docs);

        this.setState({
          loading: false,
          replies: _docsData,
        });
      },
      (error) => {
        alert("Comments failed? Check console");
        console.log(error);
      }
    );
    this.setState({ unsub2 });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading... </div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Recent comments and Replies</h2>

        <p>Most recent comments will be on TOP with # of replies and cares indicated.</p>
        <div className="flex">
          <div className="p-5 w-1/2">
            <h3 className="text-2xl font-semibold mb-5">Comments</h3>

            {this.state.comments.map((c) => {
              return (
                <div key={c.id} className="mt-4 border p-3">
                  <p className="font-semibold">{c.content}</p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">By</span> {c.user_name}{" "}
                    <Link className="underline" to={`/users/${c.user_id}`}>
                      {c.user_id}
                    </Link>
                  </p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Comment ID</span>: {`${c.id}`}
                  </p>

                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Class</span>:
                    <Link className="underline" to={`/classes/${c.class_id}`}>
                      {c.class_id}
                    </Link>{" "}
                    OR{" "}
                    <Link className="underline" to={`/on-demand/${c.class_id}`}>
                      Try on demand
                    </Link>
                  </p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Created</span>: {`${c.created_at.toDate()}`}
                  </p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Updated</span>: {`${c.updated_at.toDate()}`}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="p-5 w-1/2">
            <h3 className="text-2xl font-semibold mb-5 ">Replies</h3>
            {this.state.replies.map((r) => {
              return (
                <div key={r.id} className="mt-4 border p-3">
                  <p className="font-semibold">{r.content}</p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">By</span> {r.user_name}{" "}
                    <Link className="underline" to={`/users/${r.user_id}`}>
                      {r.user_id}
                    </Link>
                  </p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Created</span>: {`${r.created_at.toDate()}`}
                  </p>
                  <p className="mt-2 ml-6 text-sm">
                    <span className="font-medium">Parent comment</span>: {r.comment_id}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
{
  /* 
                <div key={c.id} className="mt-4">
                  <ul>
                    <li>Comment ID:{c.id}</li>
                    <li className="pl-4 font-semibold">{c.content}</li>
                    <li>
                      Class:{" "}
                      <Link className="underline" to={`/classes/${c.class_id}`}>
                        {c.class_id}
                      </Link>{" "}
                      OR <br />
                      <Link className="underline" to={`/on-demand/${c.class_id}`}>
                        View on demand
                      </Link>
                    </li>
                    <li>
                      User:{" "}
                      <Link className="underline" to={`/users/${c.user_id}`}>
                        {c.user_id}
                      </Link>
                    </li>
                    <li>Num cares {c.reactions.length}</li>
                    <li>Num replies {c.replies.length}</li>
                    <li>
                  <pre>{JSON.stringify(c, null, 4)}</pre>
                </li> 
                </ul>
                </div>
              */
}
