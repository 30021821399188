import React, { Component } from "react";
import axios from "axios";
// import { Link } from "@reach/router";

import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link } from "@reach/router";

export default class UserPage extends Component {
  state = {
    loading: true,
    error: null,
    user: {}, // CUSTOMER user, not the admin user. Our admin user is in this.props.user
    userToken: "",
    allBookings: [],
    stripe_sub_id: "",
    stripe_customer_id: "",
    staff_notes: "",
    devices: [],
    activity: [],
    oauths: [],
    settings: {},
    userClasses: {},
  };

  setData = (data) => {
    this.setState({
      loading: false,
      user: data.user,
      userToken: data.userToken,
      allBookings: data.allBookings,
      stripe_sub_id: data.user.stripe_sub_id,
      stripe_customer_id: data.user.stripe_customer_id,
      staff_notes: data.user.staff_notes,
      activity: data.activity,
      devices: data.devices,
      oauths: data.oauths,
      settings: data.settings,
      userClasses: data.user_classes,
    });
  };

  componentDidMount() {
    net.getUser(this.props.user.token, this.props.id).then((data) => {
      if (!data) {
        return;
      }
      if (data.error) {
        this.setState({ loading: false, error: data.error });
      } else {
        this.setData(data);
      }
    });
  }

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  setStatus = (stati1, stati2) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/users/${this.state.user.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        status: stati1,
        status2: stati2,
      },
    })
      .then((resp) => {
        this.setData(resp.data);
        halps.alertVanish("User updated");
      })
      .catch(net.handleError2);
  };

  makeStaff = () => {
    this.setStatus("active", "staff");
  };

  makeBeta = () => {
    this.setStatus("active", "beta");
  };

  makeScholar = () => {
    this.setStatus("active", "scholar");
  };

  makeFamily = () => {
    this.setStatus("active", "family");
  };

  makePaid = () => {
    this.setStatus("active", "sub");
  };

  makeTrial = () => {
    this.setStatus("active", "trial");
  };

  makeSub = () => {
    this.setStatus("active", "sub");
  };

  makeNew = () => {
    this.setStatus("inactive", "new");
  };

  makeNewPUF = () => {
    this.setStatus("inactive", "new_puf");
  };

  makeFree = () => {
    this.setStatus("inactive", "free");
  };

  endTrial = () => {
    this.setStatus("inactive", "trial_expired");
  };

  endSub = () => {
    this.setStatus("inactive", "canceled");
  };

  updateCust = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/users/${this.state.user.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        stripe_customer_id: this.state.stripe_customer_id,
      },
    })
      .then((resp) => {
        this.setData(resp.data);
        halps.alertInfo("Success", "User updated");
      })
      .catch(net.handleError2);
  };

  updateSub = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/users/${this.state.user.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        stripe_sub_id: this.state.stripe_sub_id,
      },
    })
      .then((resp) => {
        this.setData(resp.data);
        halps.alertInfo("Success", "User updated");
      })
      .catch(net.handleError2);
  };

  setNewContent = () => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/users/${this.state.user.id}/set-new-content`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setData(resp.data);
        halps.alertInfo("Success", "User updated");
      })
      .catch(net.handleError2);
  };

  updateNotes = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/users/${this.state.user.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        staff_notes: this.state.staff_notes,
      },
    })
      .then((resp) => {
        this.setData(resp.data);
        halps.alertInfo("Success", "User updated");
      })
      .catch(net.handleError2);
  };

  constructor(props) {
    super();
  }

  gc = (cid) => {
    return this.state.userClasses[cid];
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    if (this.state.error) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Error</h3>
          <p>{this.state.error}</p>
        </>
      );
    }
    const u = this.state.user;
    const s = u.stats;
    return (
      <div className="container mx-auto my-6">
        <h2 className="text-2xl mb-4">
          User{" "}
          <span className="italic ml-2">{`${this.state.user.first_name} ${this.state.user.last_name}`}</span>
        </h2>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Summary</h3>
            <ul className="list-disc ml-8 mt-3">
              <li className="">
                {u.first_name} {u.last_name}
              </li>
              <li className="">{u.email}</li>

              <li className="">
                Total Booked Classes
                <span className="ml-3 font-medium ">{s.total_booked_classes}</span>
              </li>

              <li className="">
                Total Joined Classes
                <span className="ml-3 font-medium ">{s.total_joined_classes}</span>
              </li>
              <li className="">
                First Booked Class
                <span className="ml-3 font-medium ">{halps.dshort(s.first_booked_class)}</span>
              </li>
              <li className="">
                Last Booked Class
                <span className="ml-3 font-medium ">
                  {halps.dshort(s.most_recent_booked_class)}
                </span>
              </li>

              <li className="">
                Total OD Views
                <span className="ml-3 font-medium ">{s.total_od_views}</span>
              </li>

              <li className="">
                Total OD 50
                <span className="ml-3 font-medium ">{s.total_od_view_50}</span>
              </li>

              <li className="">
                Total OD 80
                <span className="ml-3 font-medium ">{s.total_od_view_80}</span>
              </li>

              <li className="">
                First On Demand View
                <span className="ml-3 font-medium ">{halps.dshort(s.first_od_view)}</span>
              </li>

              <li className="">
                Last On Demand View
                <span className="ml-3 font-medium ">{halps.dshort(s.most_recent_od_view)}</span>
              </li>

              <li className="">
                Num Devices
                <span className="ml-3 font-medium ">{s.num_devices}</span>
              </li>

              <li className="">
                Last Logged In:{" "}
                <span className="ml-3 font-medium ">{halps.dshort(u.last_login)}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">TOC</h3>
            <ul className="list-disc ml-8 mt-3">
              <li>
                {" "}
                <a className="underline" href="#bookings">
                  Bookings
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#activity">
                  Activity
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#devices">
                  Devices
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#oauth">
                  Oauth
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#settings">
                  Settings
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#stats">
                  Stats
                </a>
              </li>
              <li>
                {" "}
                <a className="underline" href="#all_fields">
                  All Fields
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Billing Fields</h3>
            <ul className="list-disc ml-8 mt-3">
              <li className="">
                Status: <span className="ml-3 font-medium ">{u.status}</span>
              </li>
              <li className="">
                Status2: <span className="ml-3 font-medium ">{u.status2}</span>
              </li>

              <li className="">
                Stripe Customer ID:{" "}
                <span className="ml-3 font-medium ">{u.stripe_customer_id}</span>
              </li>
              <li className="">
                Stripe Sub ID: <span className="ml-3 font-medium ">{u.stripe_sub_id}</span>
              </li>

              <li className="">
                Trial Ends: <span className="ml-3 font-medium ">{halps.dshort(u.trial_ends)}</span>
              </li>
              <li className="">
                Trial Type: <span className="ml-3 font-medium ">{u.trial_type}</span>
              </li>
              <li className="">
                Trial Days: <span className="ml-3 font-medium ">{u.trial_days}</span>
              </li>

              <li className="">
                Member Status: <span className="ml-3 font-medium ">{u.member_status}</span>
              </li>
              <li className="">
                Member Tier: <span className="ml-3 font-medium ">{u.member_tier}</span>
              </li>
              <li className="">
                Member Plan: <span className="ml-3 font-medium ">{u.member_plan}</span>
              </li>
              <li className="">
                Member Plan Canceled:{" "}
                <span className="ml-3 font-medium ">{`${u.member_plan_canceled}`}</span>
              </li>
              <li className="">
                Member Pay Platform:{" "}
                <span className="ml-3 font-medium ">{u.member_pay_platform}</span>
              </li>
              <li className="">
                Member Ever: <span className="ml-3 font-medium ">{u.member_ever}</span>
              </li>

              <li className="">
                MemberIntervalPrice :{" "}
                <span className="ml-3 font-medium ">{u.member_interval_price}</span>
              </li>
              <li className="">
                MemberIntervalMonths :{" "}
                <span className="ml-3 font-medium ">{u.member_interval_months}</span>
              </li>
              <li className="">
                MemberMonthlyPrice :{" "}
                <span className="ml-3 font-medium ">{u.member_monthly_price}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Actions</h3>
            <ul className="list-disc ml-8 mt-3">
              <li className="underline">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={halps.webappBase() + `/user/profile?auth_token=${this.state.userToken}`}
                >
                  Login webapp as {this.state.user.email}
                </a>
              </li>
              <li className="underline mt-1">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://dashboard.stripe.com/customers/${u.stripe_customer_id}`}
                >
                  View stripe customer's dashboard ({u.stripe_customer_id})
                </a>
              </li>

              <li className="underline mt-1 cursor-pointer" onClick={this.setNewContent}>
                Set new content only (join live class to clear)
              </li>
            </ul>
            <h3 className="font-medium text-2xl text-left text-gray-500 mt-4">Billing Actions</h3>
            <ul className="list-disc ml-8 mt-3">
              {/* <li className="underline mt-1 cursor-pointer" onClick={this.makeSub}>
                Make subscriber (needs active stripe sub)
              </li>
              <li className="underline mt-1 cursor-pointer" onClick={this.makeNew}>
                Make new user (will need cc for trial)
              </li> */}

              <li className="underline mt-1 cursor-pointer" onClick={this.makeNewPUF}>
                Make new pay-up-front (PUF) user (will need cc and payment)
              </li>

              {/* <li className="underline mt-1 cursor-pointer" onClick={this.makeBeta}>
                Make beta user
              </li> */}
              {/* <li className="underline mt-1 cursor-pointer" onClick={this.makePaid}>
                Make paid subscriber
              </li> */}
              {/* <li className="underline mt-1 cursor-pointer" onClick={this.makeFree}>
                Make free user
              </li> */}
              <li className="underline mt-1 cursor-pointer" onClick={this.makeTrial}>
                Make trial subscriber (adds 14 days if already in trial)
              </li>
              <li className="underline mt-1 cursor-pointer" onClick={this.endTrial}>
                End trial (sets trial_expired)
              </li>
              <li className="underline mt-1 cursor-pointer" onClick={this.endSub}>
                Cancel subscription immediately (please cancel in stripe as well) (sets user free)
              </li>

              <li className="underline mt-1 cursor-pointer" onClick={this.makeStaff}>
                Make staff user (plz cancel/refund any subs first)
              </li>
              <li className="underline mt-1 cursor-pointer" onClick={this.makeScholar}>
                Make scholar user (plz cancel/refund any subs first)
              </li>
              <li className="underline mt-1 cursor-pointer" onClick={this.makeFamily}>
                Make family user (plz cancel/refund any subs first and note the account holder in
                staff notes)
              </li>
            </ul>
            <form action="" onSubmit={this.updateCust} className="mt-5">
              <div className="text-sm">Update Stripe Customer ID</div>
              <div className="flex">
                <input
                  type="text"
                  id="stripe_customer_id"
                  name="stripe_customer_id"
                  className="bg-f1 flex-grow rounded-md focus:ring-4 focus:ring-lav"
                  placeholder="Stripe customer id"
                  value={this.state.stripe_customer_id}
                  onChange={this.formInputUpdate}
                  required
                />

                <input
                  className="flex-none text-center mx-2 text-white bg-lav p-3 duration-300 rounded-sm hover:bg-russ focus:ring-4 focus:ring-lav"
                  type="submit"
                  value="Save"
                />
              </div>
            </form>
            <form action="" onSubmit={this.updateSub}>
              <div className="text-sm mt-2">Update Stripe Subscription ID</div>
              <div className="flex">
                <input
                  type="text"
                  id="stripe_sub_id"
                  name="stripe_sub_id"
                  className="bg-f1 flex-grow rounded-md focus:ring-4 focus:ring-lav"
                  placeholder="Stripe subscription id"
                  value={this.state.stripe_sub_id}
                  onChange={this.formInputUpdate}
                  required
                />

                <input
                  className="flex-none text-center mx-2 text-white bg-lav p-3 duration-300 rounded-sm hover:bg-russ focus:ring-4 focus:ring-lav"
                  type="submit"
                  value="Save"
                />
              </div>
            </form>

            <form action="" onSubmit={this.updateNotes}>
              <div className="text-sm mt-2">Update Staff Notes</div>
              <div className="flex">
                <textarea
                  type="text"
                  id="staff_notes"
                  name="staff_notes"
                  className="bg-f1 flex-grow rounded-md focus:ring-4 focus:ring-lav h-24"
                  placeholder="Staff notes"
                  value={this.state.staff_notes}
                  onChange={this.formInputUpdate}
                  required
                />

                <input
                  className="flex-none text-center mx-2 text-white bg-lav p-3 duration-300 rounded-sm hover:bg-russ focus:ring-4 focus:ring-lav"
                  type="submit"
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>

        <h2 id="bookings" className="text-2xl mt-4 font-medium">
          {`${u.first_name}'s`} Bookings
        </h2>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">ID</th>
              <th className="border p-4">ClassStart</th>
              <th className="border p-4">DateBooked</th>
              <th className="border p-4">ClassID</th>
              <th className="border p-4">Status</th>
              <th className="border p-4">Joined?</th>
              <th className="border p-4">Teacher</th>
            </tr>
          </thead>
          <tbody>
            {this.state.allBookings.map((b) => {
              return (
                <tr key={b.id} className="hover:bg-gray-300">
                  <td className="border p-4 underline">
                    <Link to={`/bookings/${b.id}`}>{b.id}</Link>
                  </td>
                  <td className="border p-4">{halps.dDateHour(b.class_start_time)}</td>

                  <td className="border p-4 underline">
                    <Link to={`/classes/${b.class_id}`}>{halps.dDateHour(b.created)}</Link>
                  </td>

                  <td className="border p-4 underline">
                    <Link to={`/classes/${b.class_id}`}>{b.class_id}</Link>
                  </td>

                  <td className="border p-4">{b.status}</td>
                  <td className="border p-4">{`${b.joined}`}</td>
                  <td className="border p-4">{this.gc(b.class_id).teacher_id}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <h2 id="activity" className="text-2xl mt-4 font-medium">
          {`${u.first_name}'s`} Recent Activity
        </h2>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Created</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Type2</th>
              <th className="border p-4">App</th>
              <th className="border p-4">Obj1ID</th>
              <th className="border p-4">Source</th>
              <th className="border p-4">Medium</th>
              <th className="border p-4">Campaign</th>
            </tr>
          </thead>
          <tbody>
            {this.state.activity.map((a) => {
              return (
                <tr key={a.id} className="hover:bg-gray-300">
                  <td className="border p-4">{halps.dshort(a.created)}</td>
                  <td className="border p-4">{a.type}</td>
                  <td className="border p-4">{a.type2}</td>
                  <td className="border p-4">{a.app}</td>
                  <td className="border p-4">{a.obj1_id}</td>
                  <td className="border p-4">{a.source}</td>
                  <td className="border p-4">{a.medium}</td>
                  <td className="border p-4">{a.campaign}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {this.state.devices.length > 0 ? (
          <>
            <h2 id="devices" className="text-2xl mt-4 font-medium">
              {`${u.first_name}'s`} Devices
            </h2>
            {this.state.devices.map((d) => (
              <table className="border-collapse border border-gray-400 mt-3 w-full text-left">
                {Object.keys(d)
                  .sort()
                  .map((k) => (
                    <tr key={k}>
                      <th className="border border-gray-400 p-3">{k}</th>
                      <td className="border border-gray-400 p-3">{`${d[k]}`}</td>
                    </tr>
                  ))}
              </table>
            ))}
          </>
        ) : (
          <>
            <h2 id="devices" className="text-2xl mt-4 font-medium">
              {`${u.first_name}'s`} Devices
            </h2>
            <p className="mt-3">No devices currently</p>
          </>
        )}

        <h2 id="oauth" className="mt-4 text-2xl font-medium">
          Oauth Accounts
        </h2>
        {this.state.oauths.length > 0 ? (
          <>
            {this.state.oauths.map((o) => (
              <table className="border-collapse border border-gray-400 mt-3 w-full text-left">
                {Object.keys(o)
                  .sort()
                  .map((k) => (
                    <tr key={k}>
                      <th className="border border-gray-400 p-3">{k}</th>
                      <td className="border border-gray-400 p-3">{`${o[k]}`}</td>
                    </tr>
                  ))}
              </table>
            ))}
          </>
        ) : (
          <>
            <p className="mt-3">No oauth accounts currently</p>
          </>
        )}

        <h2 id="settings" className="mt-4 text-2xl font-medium">
          User Settings
        </h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(this.state.settings)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${this.state.settings[k]}`}</td>
              </tr>
            ))}
        </table>

        <h2 id="stats" className="mt-4 text-2xl font-medium">
          User Stats
        </h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(u.stats)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${u.stats[k]}`}</td>
              </tr>
            ))}
        </table>

        <h2 id="all_fields" className="mt-4 text-2xl font-medium">
          All User Fields
        </h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(u)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${u[k]}`}</td>
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
