let envName = "dev"; // default for most cases
if (process.env.REACT_APP_BUILD_ENV === "prod") {
  envName = "prod";
}

export const commentsCollection = `${envName}_comments`;
export const reactionsCollection = `${envName}_reactions`;
export const usersCollection = `${envName}_users`;
export const repliesCollection = `${envName}_replies`;
export const env = envName;
export function isProd() {
  return envName === "prod";
}
