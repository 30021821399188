import * as halps from "./Halps";

// Axios style error handler function that will assume sentry is active
// and sweetalert bad request errors.
export function handleError2(error) {
  // Auth failed, probably session ended, pw changed, or they (or CS) killed all sessions/rolled auth version
  if (error.response && error.response.status === 401) {
    halps.alertConfirmLogout();
    return;
  }

  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data.error &&
    error.response.data.error.msg
  ) {
    const e = error.response.data.error.msg;
    console.log(`Bad request from server ${e}`);
    if (error.response.data.error.title) {
      halps.alertError(error.response.data.error.title, e);
    } else {
      halps.alertError("Oops!", e);
    }
    return error.response.data.error;
  }

  if (
    error.response &&
    error.response.status >= 500 &&
    error.response.data.error &&
    error.response.data.error.code == 'ZOOM_API'
  ) {
    halps.alertError(
      "Zoom error",
      "Seems like zoom API does not respond at the moment, try repeating request in a few seconds"
    );
    return;
  }

  console.error("Hard error from server");
  console.error(error);
  halps.alertError(
    "Server error",
    "Something bad happened :( Please hit up warren on slack or email with a detailed description of your steps, thanks!"
  );
}

export async function getUsers(token) {
  return await fetch(`${halps.apiBase()}/admin/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getUser(token, id) {
  return await fetch(`${halps.apiBase()}/admin/users/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status === 403) {
        halps.alertError(
          "Unknown User",
          "Please confirm you've logged in with the correct account and try again."
        );
        return { error: "Invalid user" };
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getClasses(token) {
  return await fetch(`${halps.apiBase()}/admin/classes`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getClass(token, id) {
  return await fetch(`${halps.apiBase()}/admin/classes/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status === 403) {
        halps.alertError(
          "Unknown User",
          "Please confirm you've logged in with the correct account and try again."
        );
        return { error: "Invalid user" };
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard, blah, test
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getClassZoomMeeting(token, id) {
  return await fetch(`${halps.apiBase()}/admin/classes/${id}/zoom`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status === 404) {
        // zoom meeting not created yet
        return { };
      } else if (res.status === 403) {
        halps.alertError(
          "Unknown User",
          "Please confirm you've logged in with the correct account and try again."
        );
        return { error: "Invalid user" };
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard, blah, test
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getEventZoomMeeting(token, id) {
  return await fetch(`${halps.apiBase()}/admin/events/${id}/zoom`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status === 404) {
        // zoom meeting not created yet
        return { };
      } else if (res.status === 403) {
        halps.alertError(
          "Unknown User",
          "Please confirm you've logged in with the correct account and try again."
        );
        return { error: "Invalid user" };
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard, blah, test
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getOnDemands(token) {
  return await fetch(`${halps.apiBase()}/admin/on-demands`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getEvents(token) {
  return await fetch(`${halps.apiBase()}/admin/events`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}

export async function getEvent(token, id) {
  return await fetch(`${halps.apiBase()}/admin/events/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "admin_2021",
    },
  }).then(
    (res) => {
      if (res.status === 401) {
        halps.alertError("Session Expired", "Your session has expired, please log in again");
        halps.logout();
      } else if (res.status === 403) {
        halps.alertError(
          "Unknown User",
          "Please confirm you've logged in with the correct account and try again."
        );
        return { error: "Invalid user" };
      } else if (res.status !== 200) {
        halps.alertError(
          "Network Error (non 200)",
          `See console or server logs, got: ${res.status}`
        );
      } else {
        return res.json().then(
          (data) => {
            // **SUCCESS**!!
            return data;
          },
          (error) => {
            halps.alertError(
              "JSON Error",
              `We are sorry there was an error with your request: ${error}`
            );
          }
        );
      }
    },
    // I think this might happen if the network is actually down hard, blah, test
    (error) => {
      halps.alertError(
        "Network Error",
        `We are sorry there was an error with your request: ${error}`
      );
    }
  );
}