import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link } from "@reach/router";
import axios from "axios";
import Swal from "sweetalert2";

export default class NotsPage extends Component {
  state = {
    loading: true,
    type: "none",
    user_id: "",
    object_id: "",
    nots: [],
    users: {},
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/nots`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          nots: resp.data.nots,
          users: resp.data.users,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.object_id || !this.state.user_id) {
      halps.alertError("", "Please enter an object and user id");
      return;
    }

    if (this.state.type === "none") {
      halps.alertError("", "Please choose a type");
      return;
    }

    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/nots`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        type: this.state.type,
        user_id: this.state.user_id,
        object_id: this.state.object_id,
      },
    })
      .then((resp) => {
        this.swalTime();
        this.setState({
          nots: resp.data.nots,
          users: resp.data.users,
          user_id: "",
          type: "none",
          object_id: "",
        });
      })
      .catch(net.handleError2);
  };

  swalTime = () => {
    Swal.fire({
      title: "Notification Sent!",
      timer: 600,
      timerProgressBar: true,
    });
  };

  getUser = (uid) => {
    return this.state.users[uid] || {};
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Notifications</h2>
        <p>
          Send a notification to a single user. Using this will create a "message" that goes into
          the user's message drawer AND try to send a push notification to every, valid device
          associated with their account.
        </p>

        <div className="w-full xl:w-6/12 bg-beau shadow-md p-5 mt-3">
          <div className="py-8 px-8 rounded-xl">
            <h2 className="text-lg">Add A New Class</h2>
            <form action="" className="mt-4" onSubmit={this.handleSubmit}>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="type"
                name="type"
                onChange={this.formInputUpdate}
                value={this.state.type}
              >
                <option value="none">Select Type</option>
                <option value="live_class_rem">Live Reminder</option>
                <option value="pce_live">PCE Reminder</option>
                <option value="live">Live Generic</option>
                <option value="od">OD Generic</option>
              </select>

              <div className="text-sm mt-2">Object ID</div>
              <input
                type="text"
                id="object_id"
                name="object_id"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Usually class ID *"
                value={this.state.object_id}
                onChange={this.formInputUpdate}
                required
              />

              <div className="text-sm mt-2">UserID or Email</div>
              <input
                type="text"
                id="user_id"
                name="user_id"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="UserID or Email *"
                value={this.state.user_id}
                onChange={this.formInputUpdate}
                required
              />

              <input
                className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
                type="submit"
                value="Send Notification"
              />
            </form>
          </div>
        </div>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-3">Created</th>
              <th className="border p-3">Type</th>
              <th className="border p-3">Title</th>
              <th className="border p-3">Status</th>
              <th className="border p-3">User</th>
              <th className="border p-3">Object ID</th>
              <th className="border p-3">Sent Push?</th>
            </tr>
          </thead>
          <tbody>
            {this.state.nots.map((n) => {
              return (
                <tr key={n.id} className="hover:bg-gray-300">
                  <td className="border p-3">{halps.dDateHour(n.created)}</td>
                  <td className="border p-3">{n.type}</td>
                  <td className="border p-3">{`${n.title.slice(0, 30)}...`}</td>
                  <td className="border p-3">{n.status}</td>
                  <td className="border p-3">
                    <Link className="underline" to={`/users/${n.user_id}`}>
                      {this.getUser(n.user_id).email}
                    </Link>
                  </td>
                  <td className="border p-3">{n.object_id}</td>
                  <td className="border p-3">{`${n.push_sent}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
