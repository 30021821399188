// firebase
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// initializaing firebase
const config = {
  apiKey: "AIzaSyAtb4RdL0B5-l9mriYe4js9tYcRz63Xkzc",
  authDomain: "chorus-all-apps-2021.firebaseapp.com",
  projectId: "chorus-all-apps-2021",
  storageBucket: "chorus-all-apps-2021.appspot.com",
  messagingSenderId: "622280570131",
  appId: "1:622280570131:web:0fe1f39772b61212c596e6",
  measurementId: "G-JEXZ6ZGJ7T",
};

const firebaseApp = initializeApp(config);

const storage = getStorage(firebaseApp);

const firestoreDb = getFirestore(firebaseApp);

export { storage, firestoreDb, firebaseApp as default };
