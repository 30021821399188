import React, { Component, useState } from "react";
// import { Link } from "@reach/router";
import axios from "axios";
import { Link } from "@reach/router";
import DatePicker from "react-datepicker";

import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { isProd } from "./common/Consts";
import { Spinline } from "./common/Icons";

const types = ["energize", "focus", "unwind", "sleep", "community", "conversations"];

export default class ClassPage extends Component {
  state = {
    loading: true,
    zoomLoadMsg: "Loading zoom info...",
    error: null,
    cls: {},
    bookings: [],
    bookingUsers: null,
    reminders: [],
    submitDisabled: false,
    zoomInfoLoading: false,
  };

  componentDidMount() {
    net.getClass(this.props.user.token, this.props.id).then((data) => {
      if (!data) {
        return;
      }
      if (data.error) {
        this.setState({ loading: false, error: data.error });
      } else {
        this.setClassState(data);
        this.fetchClassZoomMeeting();
      }
    });
  }

  setClassState = (data) => {
    this.setState({
      loading: false,
      cls: data.class,
      bookings: data.bookings,
      bookingUsers: data.bookingUsers,
      teachers: data.teachers,
      status: data.class.status,
      event_type: data.class.event_type,
      duration: data.class.duration,
      zoom_link: data.class.zoom_link,
      teacher_id: data.class.teacher_id,
      description: data.class.description,
      type: data.class.type,
      name: data.class.name,
      share_asset_link: data.class.share_asset_link,
      reminders: data.reminders,
      startTime: halps.dateFromGo(data.class.start_time),
    });
  };

  setZoomState = (data) => {
    let zoomMeeting = data.zoom_meeting ? data.zoom_meeting : null;
    let apiModel = data.api_model ? data.api_model : null;
    this.setState({
      zoomLoadMsg: null,
      zoomMeeting: zoomMeeting,
      zoomMeetingID: zoomMeeting ? zoomMeeting.meeting_id : null,
      zoomLink: zoomMeeting ? zoomMeeting.zoon_link : null,
      zoom_title: zoomMeeting
        ? zoomMeeting.title
        : `Chorus ${this.state.cls.name} with ${this.state.teacher_id
            .charAt(0)
            .toUpperCase()}${this.state.teacher_id.slice(1)}`,
      zoom_description: zoomMeeting ? zoomMeeting.description : null,
      zoomAPIModel: apiModel,
    });
  };

  setSubmitDisabled = (val) => {
    this.setState({
      submitDisabled: val,
    });
  };

  setZoomInfoLoading = (val) => {
    this.setState({
      zoomInfoLoading: val,
    });
  };

  fetchClassZoomMeeting = async () => {
    this.setZoomInfoLoading(true);
    net.getClassZoomMeeting(this.props.user.token, this.props.id).then((data) => {
      this.setZoomInfoLoading(false);
      if (!data) {
        this.setState({ zoomLoadMsg: `Fail load zoom info, see console logs` });
        return;
      }
      if (data.error) {
        this.setState({
          zoomLoadMsg: `Fetch zoom info error: ${data.error}`,
          error: `zoom error: ${data.error}`,
        });
      } else {
        this.setZoomState(data);
      }
    });
  };

  joinToggle = (bid) => {
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/bookings/${bid}/join-toggle`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setClassState(resp.data);
      })
      .catch(net.handleError2);
  };

  gu = (uid) => {
    return this.state.bookingUsers[uid] || {};
  };

  updateClass = () => {
    this.setSubmitDisabled(true);
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/classes/${this.state.cls.id}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        status: this.state.status,
        zoom_link: this.state.zoom_link,
        teacher_id: this.state.teacher_id,
        description: this.state.description,
        type: this.state.type,
        name: this.state.name,
        event_type: this.state.event_type,
        share_asset_link: this.state.share_asset_link,
        duration: parseInt(this.state.duration, 10),
        start_time: this.state.startTime,
      },
    })
      .then((resp) => {
        this.setSubmitDisabled(false);
        if (this.state.zoomAPIModel) {
          let updatedStartDt = halps.dateFromGo(resp.data.class.start_time);
          let oldStartDt = halps.dateFromGo(this.state.zoomAPIModel.start_time);
          let needUpdateZoomModel =
            resp.data.class.duration != this.state.zoomAPIModel.duration ||
            `${updatedStartDt}` != `${oldStartDt}`;
          if (needUpdateZoomModel) {
            this.fetchClassZoomMeeting();
          }
        }
        this.setClassState(resp.data);
        halps.alertVanish("Updated.");
      })
      .catch((error) => {
        this.setSubmitDisabled(false);
        net.handleError2(error);
      });
  };

  updateClassZoomMeeting = () => {
    this.setSubmitDisabled(true);
    axios({
      method: this.state.zoomMeeting ? "PUT" : "POST",
      url: `${halps.apiBase()}/admin/classes/${this.state.cls.id}/zoom`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        title: this.state.zoom_title,
        description: this.state.zoom_description,
        duration: Math.trunc(this.state.duration),
      },
    })
      .then((resp) => {
        this.setSubmitDisabled(false);
        this.setZoomState(resp.data);
        halps.alertVanish("Zoom Info Updated.");
      })
      .catch((error) => {
        this.setSubmitDisabled(false);
        net.handleError2(error);
      });
  };

  formInputUpdate = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(`SET STATE ${name}: ${value}`);

    this.setState({
      [name]: value,
    });
  };

  constructor(props) {
    super();
  }

  formSubmit = (e) => {
    e.preventDefault();
    this.updateClass();
  };

  zoomFormSubmit = (e) => {
    e.preventDefault();
    this.updateClassZoomMeeting();
  };

  rollSA = (e) => {
    e.preventDefault();
    axios({
      method: "PUT",
      url: `${halps.apiBase()}/admin/classes/${this.state.cls.id}/roll-sa`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setClassState(resp.data);
        halps.alertVanish("Rolled");
      })
      .catch(net.handleError2);
  };

  bookUser = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${halps.apiBase()}/admin/bookings`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {
        class_id: this.state.cls.id,
        user_id: this.state.book_user,
      },
    })
      .then((resp) => {
        this.setClassState(resp.data);
        halps.alertVanish("Booked");
      })
      .catch(net.handleError2);
  };

  setDate = (date) => {
    this.setState({ startTime: date });
    console.log(date);
    console.log(halps.dateToDay(date));
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    if (this.state.error) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Error</h3>
          <p>{this.state.error}</p>
        </>
      );
    }
    const c = this.state.cls;
    const gu = this.gu;
    return (
      <div className="container mx-auto mt-4">
        <h2 className="text-2xl mb-4">
          Class {c.name} <span className="underline">{halps.dshort(c.start_time)}</span> with{" "}
          {c.teacher_id}
        </h2>

        {c.status === "draft" ? (
          <h3 className="text-xl mt-3 text-red-700">
            *** This class is currently in a draft state. Please edit below to make active
          </h3>
        ) : (
          ""
        )}

        <div className="flex mt-4">
          <div className="w-full  bg-beau shadow-md">
            <div className="p-4 md:p-8">
              <h3 className="font-medium text-2xl text-left text-gray-500">Summary</h3>
              <ul className="list-disc ml-8 mt-3">
                <li className="">
                  Status: <strong>{c.status}</strong>
                </li>
                <li className="">
                  Event Type: <strong>{c.event_type}</strong>
                </li>
                <li className="">
                  Reminders 30 sent: <strong>{`${c.reminders30_sent}`}</strong>
                </li>
                <li className="">
                  Reminders 5 sent: <strong>{`${c.reminders5_sent}`}</strong>
                </li>
                <li className="">{halps.dshort(c.start_time)}</li>
                <li className="">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${halps.webappBase()}/classes/${c.id}`}
                    className="underline cursor-pointer"
                  >
                    View in webapp
                  </a>
                </li>
                <li>
                  <span onClick={this.rollSA} className="underline cursor-pointer">
                    Roll shareable asset
                  </span>
                </li>
              </ul>
              <form className="mt-3" action="" onSubmit={this.bookUser}>
                <div className="text-sm mt-2">
                  Book user to this class (<strong>won't send confirmation email</strong>)
                </div>

                <div className="flex">
                  <input
                    type="text"
                    id="book_user"
                    name="book_user"
                    className="bg-f1 flex-grow rounded-md w-full focus:ring-4 focus:ring-lav"
                    placeholder="Email or user id"
                    value={this.state.book_user}
                    onChange={this.formInputUpdate}
                    required
                  />

                  <input
                    className="flex-none text-center mx-2 text-white bg-lav p-3 duration-300 rounded-sm hover:bg-russ focus:ring-4 focus:ring-lav"
                    type="submit"
                    value="Book"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="w-full">
            {c.share_asset_link ? (
              <img className="max-h-96 inline-block mx-auto" src={c.share_asset_link} />
            ) : (
              <X />
            )}
          </div>
        </div>

        <h2 className="text-2xl mt-4">Bookings</h2>
        <p className="mt-2">
          Please see{" "}
          <a href="/" target="_blank" className="underline cursor-pointer">
            the admin home
          </a>{" "}
          for a glossary of the below user statues
        </p>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-2">Date Booked</th>
              <th className="border p-2">Email</th>
              <th className="border p-2">Name</th>
              <th className="border p-2">Joined</th>
              <th className="border p-2">Status</th>
              <th className="border p-2">Trial Ends</th>
              <th className="border p-2"># Joined</th>
              <th className="border p-2">Bk</th>
              <th className="border p-2">ODV</th>
              <th className="border p-2">ODV50</th>
            </tr>
          </thead>
          <tbody>
            {this.state.bookings.map((b) => {
              const u = gu(b.user_id);
              return (
                <tr key={b.id} className="hover:bg-gray-300">
                  <td className="border p-2">{halps.dDateHour(b.created)}</td>
                  <td className="border p-2">
                    <Link to={`/users/${b.user_id}`} className="underline text-blue-500">
                      {" "}
                      {u.email}
                    </Link>
                  </td>
                  <td className="border p-2">{`${u.first_name} ${u.last_name.slice(0, 10)}`}</td>
                  <td className="border p-2">
                    {`${b.joined}`}{" "}
                    <span
                      onClick={() => this.joinToggle(b.id)}
                      className="inline-block text-russ bg-beau ml-2 px-2 py-1 rounded-md shadow-md cursor-pointer"
                    >
                      {b.joined ? "Remove" : "Check in"}
                    </span>
                  </td>
                  {/* <td className="border p-2">{this.reminderSent(b)}</td> */}
                  <td className="border p-2">{b.status}</td>
                  <td className="border p-2">
                    {u.status2.startsWith("trial") ? halps.dayshort(u.trial_ends) : "-"}
                  </td>
                  <td className="border p-2">{`${u.stats.total_joined_classes}`}</td>
                  <td className="border p-2">{`${u.stats.total_booked_classes}`}</td>
                  <td className="border p-2">{`${u.stats.total_od_views}`}</td>
                  <td className="border p-2">{`${u.stats.total_od_view_50}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
          <div className="p-4 md:p-8">
            <h3 className="font-medium text-2xl text-left text-gray-500">Edit Class</h3>
            <div className="mt-2 text-sm underline cursor-pointer">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/document/d/1bn-_bfdhxXhftLDDouE9P-9YBUWNpDY7oSag7Zcugsk/edit#heading=h.7fy58k8udk1v"
              >
                Please read more about class data and rendering here
              </a>
            </div>
            <form className="mt-3" action="" onSubmit={this.formSubmit}>
              <div className="text-sm">Status</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="status"
                name="status"
                onChange={this.formInputUpdate}
                value={this.state.status}
              >
                <option value="draft">Draft</option>
                <option value="active">Active</option>
                <option value="canceled">Canceled</option>
              </select>

              {isProd() ? (
                "" // Only show the start time date editor in DEV/local
              ) : (
                <>
                  <div className="text-sm mt-2">Start DateTime (PST)(Dev Only)</div>
                  <DatePicker
                    className="bg-f1 rounded-md  w-full focus:ring-4 focus:ring-lav"
                    selected={this.state.startTime}
                    onChange={(date) => this.setDate(date)}
                    dateFormat="yyyy/MM/dd HH:mm"
                    placeholderText="YYYY/MM/DD HH:mm"
                    showTimeSelect
                  />
                </>
              )}

              <div className="text-sm">Event Type</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="event_type"
                name="event_type"
                onChange={this.formInputUpdate}
                value={this.state.event_type}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>

              <div className="text-sm mt-2">Duration</div>
              <select
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                id="duration"
                name="duration"
                onChange={this.formInputUpdate}
                value={this.state.duration}
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>

              <div className="text-sm mt-2">Teacher</div>
              <select
                className="bg-f1  rounded-md w-full focus:ring-4 focus:ring-lav"
                id="teacher_id"
                name="teacher_id"
                onChange={this.formInputUpdate}
                value={this.state.teacher_id}
              >
                <option value="none">Teacher</option>
                {this.state.teachers.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.first_name}
                  </option>
                ))}
              </select>

              {/* <div className="text-sm mt-2">Zoom Link</div>
              <input
                type="url"
                id="zoom_link"
                name="zoom_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Zoom Link *"
                value={this.state.zoom_link}
                onChange={this.formInputUpdate}
                required
              /> */}

              <div className="text-sm mt-2">Type</div>
              <select
                className="bg-f1  rounded-md w-full focus:ring-4 focus:ring-lav"
                id="type"
                name="type"
                onChange={this.formInputUpdate}
                value={this.state.type}
              >
                <option value="none">Type</option>
                {types.map((t) => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </select>

              <div className="text-sm mt-2">Name</div>
              <input
                type="text"
                id="name"
                name="name"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Name"
                value={this.state.name}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Description</div>
              <input
                type="text"
                id="description"
                name="description"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Description"
                value={this.state.description}
                onChange={this.formInputUpdate}
              />

              <div className="text-sm mt-2">Shareable Asset</div>
              <input
                type="text"
                id="share_asset_link"
                name="share_asset_link"
                className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                placeholder="Shareable Asset Link"
                value={this.state.share_asset_link}
                onChange={this.formInputUpdate}
              />

              <button
                disabled={this.state.submitDisabled ? "true" : ""}
                type="submit"
                className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
              >
                {this.state.submitDisabled ? <Spinline /> : "Save Changes"}
              </button>
            </form>
          </div>
        </div>

        {this.state.zoomLoadMsg && (
          <div className="w-full xl:w-6/12 mt-4">
            <h3 className="font-medium text-2xl mt-5 mb-5 text-center">{this.state.zoomLoadMsg}</h3>
          </div>
        )}

        {this.state.zoomInfoLoading && <Spinline />}

        {!this.state.zoomLoadMsg && !this.state.zoomInfoLoading && (
          <div className="w-full xl:w-6/12 bg-beau shadow-md mt-4">
            <div className="p-4 md:p-8">
              <h3 className="font-medium text-2xl text-left text-gray-500">
                {this.state.zoomMeetingID ? "Edit " : "Create "}
                Zoom Meeting
              </h3>
              {this.state.zoomMeetingID ? (
                <div className="mt-3">
                  <a
                    className="underline"
                    rel="noreferrer"
                    target="_blank"
                    href={`https://us02web.zoom.us/meeting/${this.state.zoomMeetingID}`}
                  >
                    View in zoom dashboard
                  </a>
                </div>
              ) : (
                ""
              )}
              <form className="mt-3" action="" onSubmit={this.zoomFormSubmit}>
                <div className="text-sm mt-2">Zoom Title</div>
                <input
                  type="text"
                  id="zoom_title"
                  name="zoom_title"
                  className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                  placeholder="Title"
                  value={this.state.zoom_title}
                  onChange={this.formInputUpdate}
                  required
                />

                <div className="text-sm mt-2">Description</div>
                <input
                  type="text"
                  id="zoom_description"
                  name="zoom_description"
                  className="bg-f1 rounded-md w-full focus:ring-4 focus:ring-lav"
                  placeholder="Description"
                  value={this.state.zoom_description}
                  onChange={this.formInputUpdate}
                />

                {/* <div className="text-sm mt-2">Duration</div>
              <select
                className="bg-f1 rounded-md  focus:ring-4 focus:ring-lav"
                id="zoom_duration_hours"
                name="zoom_duration_hours"
                onChange={this.formInputUpdate}
                value={this.state.zoom_duration_hours}
              >
                <option value="0" default>0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select> <label className="text-sm">hr  </label>
              <select
                className="bg-f1 rounded-md  focus:ring-4 focus:ring-lav"
                id="zoom_duration_minutes"
                name="zoom_duration_minutes"
                onChange={this.formInputUpdate}
                value={this.state.zoom_duration_minutes}
              >
                <option value="0">0</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select> min */}

                <button
                  disabled={this.state.submitDisabled ? "true" : ""}
                  type="submit"
                  className="block text-center text-white bg-lav mt-3 p-3 duration-300 rounded-sm hover:bg-russ w-full focus:ring-4 focus:ring-lav"
                >
                  {this.state.submitDisabled ? <Spinline /> : "Save Changes"}
                </button>
              </form>
            </div>
          </div>
        )}

        <h2 className="mt-4 text-2xl font-medium">All Fields</h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {Object.keys(c)
            .sort()
            .map((k) => (
              <tr key={k}>
                <th className="border border-gray-400 p-3">{k}</th>
                <td className="border border-gray-400 p-3">{`${c[k]}`}</td>
              </tr>
            ))}
        </table>

        <h2 className="mt-4 text-2xl font-medium">Zoom Meeting Info</h2>

        <table className="border-collapse border border-gray-400 mt-5 w-full text-left">
          {!isProd() && this.state.zoomInfoLoading && <Spinline />}
          {!isProd() &&
            !this.state.zoomInfoLoading &&
            !this.state.zoomAPIModel &&
            "Zoom meeting is not created yet, use zoom form above to create one"}
          {!isProd() &&
            !this.state.zoomInfoLoading &&
            this.state.zoomAPIModel &&
            Object.keys(this.state.zoomAPIModel)
              .sort()
              .map((k) => (
                <tr key={k}>
                  <th className="border border-gray-400 p-3">{k}</th>
                  <td className="border border-gray-400 p-3">
                    {typeof this.state.zoomAPIModel[k] === "object" &&
                    this.state.zoomAPIModel[k] !== null &&
                    k !== "settings"
                      ? JSON.stringify(this.state.zoomAPIModel[k])
                      : `${this.state.zoomAPIModel[k]}`}
                  </td>
                </tr>
              ))}
          {!isProd() &&
            !this.state.zoomInfoLoading &&
            this.state.zoomAPIModel &&
            this.state.zoomAPIModel["settings"] &&
            Object.keys(this.state.zoomAPIModel["settings"])
              .sort()
              .map((k) => (
                <tr key={k}>
                  <th className="border border-gray-400 p-3">settings.{k}</th>
                  <td className="border border-gray-400 p-3">
                    {typeof (
                      this.state.zoomAPIModel["settings"][k] === "object" &&
                      this.state.zoomAPIModel[k] !== null
                    )
                      ? JSON.stringify(this.state.zoomAPIModel["settings"][k])
                      : `${this.state.zoomAPIModel["settings"][k]}`}
                  </td>
                </tr>
              ))}
        </table>
      </div>
    );
  }
}

function X(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="329pt"
      viewBox="0 0 329.26933 329"
      width="329pt"
    >
      <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
    </svg>
  );
}
