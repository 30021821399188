import React, { Component } from "react";
import * as halps from "./common/Halps";
import * as net from "./common/Net";
import { Link } from "@reach/router";
import axios from "axios";

export default class PastClassesPage extends Component {
  state = {
    loading: true,
    classes: [],
    classSlots: [],
  };

  componentDidMount() {
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/classes/past`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        this.setState({
          loading: false,
          classes: resp.data.classes,
          classSlots: resp.data.classSlots,
        });
      })
      .catch(net.handleError2);
  }

  constructor(props) {
    super();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl">Past Classes</h2>

        <div className="mt-2">
          <Link to="/classes" className="text-sm underline cursor-pointer">
            View current classes
          </Link>
        </div>

        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="border p-4">Start Time</th>
              <th className="border p-4">Status</th>
              <th className="border p-4">Teacher</th>
              <th className="border p-4">Booked</th>
              <th className="border p-4">Type</th>
              <th className="border p-4">Duration</th>
            </tr>
          </thead>
          <tbody>
            {this.state.classes.map((c) => {
              return (
                <tr key={c.id} className="hover:bg-gray-300">
                  <td className="border p-4  underline cursor-pointer">
                    <Link to={`/classes/${c.id}`}>{halps.dshort(c.start_time)}</Link>
                  </td>
                  <td className={`border p-4 ${c.status === "canceled" ? "text-red-600" : ""}`}>
                    {c.status}
                  </td>
                  <td className="border p-4 uppercase">{c.teacher_id}</td>
                  <td className="border p-4">{c.bookings_count}</td>
                  <td className="border p-4">{c.type}</td>
                  <td className="border p-4">{c.duration}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
