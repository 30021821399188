import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import axios from "axios";

import * as halps from "./common/Halps";
import * as net from "./common/Net";

export default class UsersPage extends Component {
  state = {
    loading: true,
    users: [],
    findEmail: "",
  };

  componentDidMount() {
    const search = halps.getQueryParam("search");
    if (search) {
      this.setState({ findEmail: search, loading: false }, () => {
        this.findUser();
      });
    } else {
      net.getUsers(this.props.user.token).then((data) => {
        if (!data) {
          return;
        }
        this.setState({ loading: false, users: data.users });
      });
    }
  }

  constructor(props) {
    super();
  }

  findUser = (e) => {
    if (e) {
      e.preventDefault();
    }
    axios({
      method: "GET",
      url: `${halps.apiBase()}/admin/users/find?email=${encodeURIComponent(this.state.findEmail)}`,
      headers: { Authorization: "Bearer " + this.props.user.token, "X-APP-ID": "admin_2021" },
      data: {},
    })
      .then((resp) => {
        const u = resp.data.user;
        const us = resp.data.users;
        if (us) {
          this.setState({ users: us });
        } else if (u) {
          navigate(`/users/${u.id}`);
        } else {
          halps.alertError("Not found", "No user found for that email");
        }
      })
      .catch(net.handleError2);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <>
          <h3 className="font-medium text-2xl mt-5 mb-5 text-center">Loading...</h3>
        </>
      );
    }
    return (
      <div className="md:container mx-auto mt-4">
        <h2 className="text-2xl mb-4">All Users</h2>
        <p>Most recent and recently updated users on top. Your local time.</p>
        <div className="w-full md:w-4/12 bg-beau shadow-md mt-4">
          <div className="p-4">
            <h3 className="font-medium text-xl text-left text-gray-600">Find User By Email</h3>

            <div className="flex mt-3">
              <form action="" onSubmit={this.findUser}>
                <input
                  type="text"
                  id="findEmail"
                  name="findEmail"
                  className="bg-f1 rounded-md flex-grow focus:ring-4 focus:ring-lav"
                  placeholder="Email *"
                  value={this.state.findEmail}
                  onChange={this.handleInputChange}
                  required
                />
                <button type="submit" className="ml-2 px-4 py-2 inline-block text-white bg-lav">
                  Find
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(this.props.user, null, 4)}</pre>; */}
        <table className="border mt-5 w-full text-left">
          <thead>
            <tr>
              <th className="border p-2">Email</th>
              <th className="border p-2">First Name</th>
              <th className="border p-2">Last Name</th>
              <th className="border p-2">Status</th>
              <th className="border p-2">Created</th>
              <th className="border p-2">Trial Ends</th>
              <th className="border p-2">Bk</th>
              <th className="border p-2">Join</th>
              <th className="border p-2 text-indigo-500">Dev</th>
              <th className="border p-2">ODV</th>
              <th className="border p-2">ODV50</th>
              <th className="border p-2">ODV80</th>
            </tr>
          </thead>

          {this.state.users.map((u) => {
            console.log(u.stats);
            return (
              <tbody key={u.id}>
                <tr className="hover:bg-gray-300">
                  <td className="border p-2 underline">
                    <Link to={`/users/${u.id}`}>{u.email}</Link>
                  </td>
                  <td className="border p-2">{u.first_name}</td>
                  <td className="border p-2">{u.last_name}</td>
                  <td className="border p-2">{`${u.status}/${u.status2}`}</td>
                  <td className="border p-2">{halps.dshort(u.created)}</td>
                  <td className="border p-2">{halps.dshort(u.trial_ends)}</td>
                  <td className="border p-2">{`${u.stats.total_booked_classes}`}</td>
                  <td className="border p-2">{`${u.stats.total_joined_classes}`}</td>
                  <td className="border p-2 text-indigo-500">{`${u.stats.num_devices}`}</td>
                  <td className="border p-2">{`${u.stats.total_od_views}`}</td>
                  <td className="border p-2">{`${u.stats.total_od_view_50}`}</td>
                  <td className="border p-2">{`${u.stats.total_od_view_80}`}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    );
  }
}
